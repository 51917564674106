import React, { useEffect, useState } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Login from './Pages/Login';
import TesteRedux from './Components/ReduxTeste/index'
import Drag from './Components/Dragdrop';
import ProntuarioListagemModelosSalvos from './Pages/Prontuarios/ListagemModelosSalvos';
import Topo from './Components/MenuTopo';
import NovoModeloProntuario from './Pages/Prontuarios/NovoModeloProntuario';
import Financeiro from './Pages/Financeiro/Resumo'
import AtendenteListagem from './Pages/Atendentes';
import Usuarios from './Pages/Usuarios';
import Clinicas from './Pages/Clinicas';
import Inicio from './Pages/Home';
import Cupons from './Pages/Cupons';
import NotificacoesMensagens from './Pages/NotificacoesMensagens';
import TableHistorico from './Pages/NotificacoesMensagens/components/HistoricoEnvios';
import Perfil from './Pages/PerfilAdm';
import RelatorioFinanceiro from './Pages/Relatorios/Financeiro';
import RelatorioAgendamento from './Pages/Relatorios/Agendamentos';
import RelatorioUsuarios from './Pages/Relatorios/Usuarios';

export default function Routes() {

    const Home = () => {
        return (

            <div>
                <Topo />
                <Inicio />
            </div>

        )

    }




    return (

        <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/listagem-modelos" component={() =>
                <div>
                    <Topo />
                    <ProntuarioListagemModelosSalvos />
                </div>
            }>
            </Route>
            <Route path="/usuarios" component={() =>
                <div>
                    <Topo />
                    <Usuarios />
                </div>
            }>
            </Route>
            <Route path="/clinicas" component={() =>
                <div>
                    <Topo />
                    <Clinicas />
                </div>
            }>
            </Route>
            <Route path="/cupons" component={() =>
                <div>
                    <Topo />
                    <Cupons />
                </div>
            }>
            </Route>
            <Route path="/perfil" component={() =>
                <div>
                    <Topo />
                    <Perfil />
                </div>
            }>
            </Route>
            <Route path="/notificacoes" component={() =>
                <div>
                    <Topo />
                    <NotificacoesMensagens />
                </div>
            }>
            </Route>
            <Route path="/historico-notificacoes" component={() =>
                <div>
                    <Topo />
                    <TableHistorico />
                </div>
            }>
            </Route>
            <Route path="/home" component={() =>
                <div>
                    <Topo />
                    <Inicio />
                </div>
            }>
            </Route>
            <Route path="/novo-modelo" component={() =>
               
                    <div>
                        <Topo />
                        <NovoModeloProntuario />
                    </div>
                
            }>
            </Route>
            <Route path="/financeiro" component={() =>
             localStorage.getItem('/financeiro') === 'true' ?
                <div>
                    <Topo />
                    <Financeiro />
                </div>
                  : Home()
            }>
          

            </Route>
            <Route path="/relatorio-financeiro" component={() =>
                <div>
                    <Topo />
                    <RelatorioFinanceiro />
                </div>
            }>
            </Route>
            <Route path="/relatorio-agendamento" component={() =>
                <div>
                    <Topo />
                    <RelatorioAgendamento />
                </div>
            }>
            </Route>
            <Route path="/relatorio-usuarios" component={() =>
                <div>
                    <Topo />
                    <RelatorioUsuarios/>
                </div>
            }>
            </Route>
        </Switch>
    )

}


//
