export function campovazio(e) {
    // if (e.length > 2) {
    //     return 'erro esse aqui e maior que 2'
    // }
    
    if (e === "") {
        return 'Esse campo precisa ser preenchido'
    }
    else {
        return false
    }
}

export function teste2(e) {
    if (e.length > 5) {
        return true
    } else {
        return false
    }
}

export function email(e) {
    if (e === "") {
        return 'esse campo precisa ser preenchido'
    }
    if (!e.includes('@')){
        return 'Preencha o email corretamente'
    }
    if (!e.includes('.com')){
        return 'Preencha o email corretamente'
    }
    else {
        return false
    }
   
}
export function CPF(e) {
    if (e === "") {
        return 'esse campo precisa ser preenchido'
    }
    if (e.length <= 11) {
        return 'Preencha todo o número do cpf'
    }
 
    else {
        return false
    }
   
}
export function RG(e) {
    if (e === "") {
        return 'esse campo precisa ser preenchido'
    }
  
 
    else {
        return false
    }
   
}
export function phone(e) {
   
    if (e === "") {
        return 'esse campo precisa ser preenchido'
    }if(e.length < 13){
        
        return 'Telefone inválido'
    }
  
 
    else {
        return false
    }
   
}
export function cepValid(e) {
   
    if (e === "") {
        return 'esse campo precisa ser preenchido'
    }if(e.length < 10){
        
        return 'CEP inválido'
    }
  
 
    else {
        return false
    }
   
}