import React, { useState, useEffect } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import ListIcon from '@material-ui/icons/List';
import '../../css/NovoModelo.css';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { StateGlobal } from '../../../../../ContextAPI/context'
import { keys } from '@material-ui/core/styles/createBreakpoints';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '97%',
    },
  },
}));



export default function CardTextoCurto(props) {
  const classes = useStyles();
  const history = useHistory();
  const [newname, setNewname] = useState();
  const [required, setRequirede] = useState(false);
  const [checked, setcheck] = useState(false)
  const [titulocampo , setTituloCampo] =useState();
  const [file, setFile] = useState();
  const [label, setLabel] = useState("Selecione uma imagem");


  function onchange(e) {
    setTituloCampo(e.target.value)
    const { name, value } = e.target;
    


    montagemstate.map((item, index) => {
      if (props.tempId === item.tempId) {
        montagemstate[index].title = value

      }
    })

  }

  const {
    montagemstate,
    setMontagem,
    setIdcomponentAtual,
    setAtt,
    att,
    idComponentAtual,
    sessaostate,
    viewProntuario
  } = StateGlobal();

  useEffect(() => {


   montagemstate.map((item) => {
    if(item.tempId === props.tempId){
      setTituloCampo(item.title)
      if(item.required == 0){
        setcheck(false)
      }else{
        setcheck(true)
      }
    }
    })

  }, [idComponentAtual])

  const idImg = `selecao-arquivo-${props.tempId}`

  function onClickImg(e){
    
    if(document.getElementById(idImg).files[0]){
        const t = document.getElementById(idImg).files[0].name
        setFile(t)
        setLabel("")

        var file = document.getElementById(idImg).files[0];

        var reader = new FileReader();


        reader.onloadend = function() {
       
        }

        reader.readAsDataURL(file);
    }
  }
 

  return (
    <div className="CardTextoCurto-container" >
      {/* {props.tempId} */}
      <ListIcon className="icon-list-card-input" />
      <div className="container-card-input">
        <h3 className="h3-title-card-input">IMAGEM</h3>
        <form 
          className={classes.root}
          onSubmit={(e) => e.preventDefault()}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="tituloDoCampo"
            name={props.tempId}
            value={titulocampo}
            onChange={onchange}
            label="Título do campo"
            variant="outlined"
            error={montagemstate.filter(item => item.tempId === props.tempId && item.title === "").length !== 0 ? true : false}
            helperText={montagemstate.filter(item => item.tempId === props.tempId && item.title === "").length !== 0 ? 'Titulo Obrigatório' : ''}
            disabled={viewProntuario}
          />
        </form>
        <div className="Card-option">
          <IconButton name={props.tempId} 
            disabled={viewProntuario}
            onClick={() => {
            montagemstate.map((item, index) => {
              if (props.tempId === item.tempId) {
                const array = montagemstate
                array.splice(index, 1)

                setMontagem(array)
                setAtt(!att)

              }
            })

 
          }}>
            <DeleteIcon />
          </IconButton>
          <div className="divisor"></div>
          {/* {checked ? 'true ' : 'false'} */}
          <FormControlLabel
            className="label-color"
            name='switch'
            onChange={() => {
              setcheck(!checked)
           


              montagemstate.map((item, index) => {
                if (props.tempId === item.tempId) {

                  item.required = !checked

               
                }
              })

            }}
            checked={checked}
           
            control={<Switch color="primary" />}
            label="Obrigatório"
            labelPlacement="start"
            disabled={viewProntuario}

          />


        </div>
      </div>
    </div>
  );
}