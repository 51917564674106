import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import CircularLoad from '..';
import DialogContentText from '@material-ui/core/DialogContentText';


export default function Modal(props) {

    const [load, setLoad] = useState()
    const [open, setOpen] = useState(props.open)





    useEffect(() => {

        setOpen(props.open)

    }, [props.open])


    function handleExclusao(value) {
        return value

    }

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.titulo}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.desc}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        props.close()
                        setOpen(false)

                    }} variant="contained" className="color-secondary" color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={() => {                

                            props.delete()
                            props.close()                      


                    }} variant="contained" color="primary" className="color-primary" autoFocus>
                        {!load ? 'Concluir' : 'carregando ...'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}