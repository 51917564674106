import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import api from '../../../Services/api'
import CircularLoad from '../../../Components/Load';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import '../css/Usuarios.css';

const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      }
    },
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
      width: '100%'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '96%',
      },
  }));
  

export default function ModalExcluir(props) { 

    const [load, setLoad] = useState(props.load);
    const classes = useStyles();
    const [id, setId] = useState(props.id);
    const [openModalEditar, setOpenModalEditar] = useState(props.open);
    const [selectedDate, setSelectedDate] = React.useState(props.data);
    const [message, setMessage] = React.useState('');
    const [typeAlert, setTypeAlert] = useState('');

    const handleDateChange = (date) => {
      setSelectedDate(date.target.value);
    };

    useEffect(() => {
        setLoad(props.load);
        setId(props.id);
        setSelectedDate(props.data);
        setOpenModalEditar(props.open);
    }, [props])


    function handleEditar(value){
      if(value){
        renoveLicensa()
      }else{
        props.handleModal(value)
      }
    }

    function renoveLicensa(){
      setLoad(true);
      const data ={
        "expired_at": selectedDate
      }

      const config = {
        headers: { Authorization: localStorage.getItem('token') }
      };
  
        api.put(`adm/doctor/plan/expired/${id}`, data, config)
        .then((response)=>{
          setLoad(false);
          setTypeAlert('success')
          setMessage('Data de expiração alterada com sucesso!')
          setTimeout(() => {
            props.handleModal(true)
            setTypeAlert('')
            setMessage('')
          }, 2500);
        }).catch((error)=>{
          setTypeAlert('error')
          setMessage('Não foi possível alterar a data de expiração! Por favor, tente novamente mais tarde.')
          setTimeout(() => {
            props.handleModal(true)
            setTypeAlert('')
            setMessage('')
          }, 2500);
          setLoad(false)
        })
    
    }
  

    return(
            <div>
                <ThemeProvider theme={theme}>
                <Dialog
                    className="modal-editar-licenca"
                    open={openModalEditar}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Alterar expiração da licença</DialogTitle>
                    {message !== ''?
                      <Alert className="messagensFixed" variant="filled" severity={typeAlert}>
                        {message}
                      </Alert>
                  :''}
                    <form className={classes.root} noValidate autoComplete="off">
                      {selectedDate !=''?
                        <DialogContent>
                        <TextField
                            id="date"
                            label="Data de expiração da licença"
                            type="date"
                            variant="outlined"
                            defaultValue={selectedDate}
                            onChange={handleDateChange}
                            className={classes.textField}
                            InputLabelProps={{
                            shrink: true,
                            }}
                        />
                        </DialogContent>
                        :''}
                        <DialogActions>
                        <Button onClick={()=>handleEditar(false)} color="default">
                            Cancelar
                        </Button>
                        <Button  onClick={()=>handleEditar(true)} variant="contained" className="color-primary" color="primary" autoFocus>
                            {!load ? 'Salvar Alteração' : <CircularLoad/>}
                        </Button>
                        </DialogActions>
                    </form>
                </Dialog>
                </ThemeProvider>
            </div>
     );
}