import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import LaunchIcon from '@material-ui/icons/Launch';
import api from '../../../Services/api3'
import {useHistory} from 'react-router-dom';
import Modal from './ModalEditarLicenca';
import { telefones } from '../../../Components/ReduxTeste/Validate';
import CircularLoad from '../../../Components/Load';
import '../css/Usuarios.css';
import { map } from 'async';
import Search from '@material-ui/icons/Search';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'codigo', numeric: true, disablePadding: false, label: '#' },
  { id: 'dataCadastro', numeric: false, disablePadding: false, label: 'Data de cadastro' },
  { id: 'nome', numeric: false, disablePadding: false, label: 'Nome' },
  { id: 'email', numeric: false, disablePadding: false, label: 'E-mail' },
  { id: 'telefone', numeric: true, disablePadding: false, label: 'Telefone' },
  { id: 'cidade', numeric: false, disablePadding: false, label: 'Cidade' },
  { id: 'estado', numeric: false, disablePadding: false, label: 'Estado' },
  { id: 'profissao', numeric: false, disablePadding: false, label: 'Profissão' },
  { id: 'especialidade', numeric: false, disablePadding: false, label: 'Especialidade' },
  { id: 'dataExpiracao', numeric: false, disablePadding: false, label: 'Data de expiração' },
  { id: 'acao', numeric: false, disablePadding: true, label: 'Ações' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };


  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    backgroundColor: '#F3F3F3'
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function TableHistorico({getTableData}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [totalRows, setTotatRows] = useState()
  const [rows, setRows] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [dataInicial, setDataInicial] = React.useState('');
  const [dataFinal, setDataFinal] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [typeAlert, setTypeAlert] = useState('');
  const [semUsuario, setSemUsuarios] = React.useState(false);
  const [semResultado, setSemResultado] = React.useState(false);
  const [dataExpiracao, setDataExpiracao] = React.useState('');
  const [idEditar, setIdEditar] = React.useState('');
  const [load, setLoad] = useState(true)
  const [openmodalEditar, setOpenmodalEditar] = useState(false);
  const [paginaAtual, setPaginaAtual] = useState(0)
  const [limparStatus, setLimparStatus] = useState(false)
  const history = useHistory();
  const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      }
    },
  }, ptBR);

 
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    
    setPaginaAtual(newPage)
    listaUsuarios(newPage)

  };


  function close() {
    setOpenmodalEditar(false)
  }

  const handleModalEditar = (value) => {
      close()
      listaUsuarios(paginaAtual)
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  
  function convertData(value){
    let data = String(value)
  
    data  = data.split('T')

    data = data[0]

    data = data.split('-')

    data = `${data[2]}/${data[1]}/${data[0]}`

    return(data)

  }
  function convertDataD(value){
    let data = String(value)
  
    data  = data.split(' ')

    data = data[0]

    data = data.split('-')

    data = `${data[2]}/${data[1]}/${data[0]}`

    return(data)

  }

  function desconvertDataD(value){
    let data = String(value)
  

    data = data.split('/')

    data = `${data[2]}-${data[1]}-${data[0]}`

    return(data)

  }
  
  function listaUsuarios(newPage){
    convertData()
    convertDataD()
    setSemResultado(false)
    setLoad(true)
    
    let pagina = ''
    if(newPage){
      pagina = newPage + 1 

    }else{
      pagina = page + 1
    }

    const data ={
      "search": search,
      "created_at" : dataInicial,
      "finished_at"   : dataFinal,
    }

    const config = {
      headers: { Authorization: localStorage.getItem('token') }
    };
    
      api.post(`/adm/doctor/list?page=${pagina}`, data, config)
      .then((response)=>{
        if(response.data.data.data.length<1){
          setSemResultado(true)
          
          setMessage('Nenhum resultado encontrado')
          setTypeAlert('error')
        }else{
          setSemResultado(false)
          setMessage('')
          setTypeAlert('')
          setTotatRows(response.data.data.total)
          getTableData(response.data.data.data)
          let arr = []
          
          let listar = response.data.data.data
          listar.forEach((item)=>{
            
            var data = {
              codigo: item.doctor.id,
              dataCadastro: item.doctor.created_at ,
              nome: item.name  ,
              email: item.email,
              telefone: item.doctor.phone  ,
              cidade: item.doctor.city, 
              estado: item.doctor.state,
              profissao:  item.doctor.speciality.speciality_category.name,
              especialidadeidade: item.doctor.speciality.name ,
              dataExpiracao: item.doctor.plan?.expired_at ,
            }
        
            arr.push(data)
            
            
          })
          setRows(arr)
          setLoad(false) 
        }
      }).catch((error)=>{
        setLoad(false)
        setSemResultado(true)
        setMessage('Nenhum resultado encontrado')
        setTypeAlert('error')
      })
  }

  useEffect(() => {
    listaUsuarios();
  }, []);


  useEffect(() => {
    if(search===''){
      listaUsuarios()
    }
  
  },[search]);
  

  useEffect(() => {
    listaUsuarios()
  }, [limparStatus]);

  const capturaDataFinal = (prop) => (event) => {
    setDataFinal(event.target.value);
};

const capturaDataInicial = (prop) => (event) => {
  setDataInicial(event.target.value);
};

function alterarExpiracao(id,data){
  setOpenmodalEditar(true)
  setIdEditar(id)
  setDataExpiracao(desconvertDataD(data))
}
function limpar(){
  setSearch('')
  setDataInicial('');
  setDataFinal('');
  setLimparStatus(!limparStatus)
  setLoad(true)
}

const busca = (event) => {
  if (event.key === 'Enter') {
    setLoad(true)
    listaUsuarios()
  } if (event._reactName === 'onClick') {
    setLoad(true)
    listaUsuarios()
  }
  return true
}


  return (
    <div className={classes.root}>
         <ThemeProvider theme={theme}>
      {!semUsuario ?
      <div className="tableuser">
      <div className="historico-user-envolve-busca">
        <TextField className="buscaHistorico"
          id="outlined-basic" 
          value={search}
          onKeyDown={busca}
          onChange={(ev) => setSearch(ev.target.value)} 
          label="Buscar por usuário"  
          color="primary"  
          variant="outlined" 
          InputProps={{
            endAdornment: <InputAdornment position="start"><SearchIcon onClick={(event) => busca(event)} className="iconSearchColor"/></InputAdornment>,
        }}/>
 
        <div className="historico-user-input-data">
        <TextField className="date"
          id="outlined-basic" 
          value={dataInicial}
          onChange={capturaDataInicial()}
          label="Data inicial"  
          color="primary"  
          variant="outlined" 
          type="date" 
          maxLength = "10"
          inputProps ={{maxLength:10}}
          InputLabelProps={{
            shrink: true,
          }}
         />
        <TextField className="date"
          id="outlined-basic" 
          value={dataFinal}
          label="Data final"
          onChange={capturaDataFinal()}  
          color="primary"  
          variant="outlined"
          type="date" 
          maxLength = "10"
          inputProps ={{maxLength:10}}
          InputLabelProps={{
            shrink: true,
          }}
         />
          <div className="envolve-btn-filtro-user">
              <Button variant="contained" color="primary" className="color-primary" onClick={listaUsuarios}>
                Filtrar data
              </Button>
              <Button variant="contained" color="secondary" className="color-secondary" onClick={()=>{limpar()}}>
                Limpar filtro
              </Button>
          </div>
        </div>
        </div>
        <div className="historico-user-div-msg">
            {message !== ''?
              <Alert className="messagensFixed" variant="filled" severity={typeAlert}>
                 {message}
              </Alert>
                  :''}
        </div>
        {!semResultado ?
        !load ? 
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={clsx(classes.table,"historico-user-table")} 
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
           
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.codigo);
                  const labelId = `enhanced-table-checkbox-${index}`;
                 
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.codigo)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.codigo}
                      selected={isItemSelected}
                    >
                      <TableCell align="left" component="th" id={labelId}>
                        {row.codigo}
                      </TableCell>
                      <TableCell align="left">{row.dataCadastro ? convertData(row.dataCadastro) : 'Não cadastrado' }</TableCell>
                      <TableCell align="left">{row.nome ? row.nome : 'Não cadastrado'}</TableCell>
                      <TableCell className='tabelaEmail' align="left">{row.email ? row.email : 'Não cadastrado'}</TableCell>
                      <TableCell className='tabelaTel' align="left">{row.telefone ? telefones(row.telefone) : 'Não cadastrado'}</TableCell>
                      <TableCell align="left">{row.cidade ? row.cidade : 'Não cadastrado'}</TableCell>
                      <TableCell align="left">{row.estado ? row.estado : 'Não cadastrado'}</TableCell>
                      <TableCell align="left">{row.profissao ? row.profissao : 'Não cadastrado'}</TableCell>
                      <TableCell align="left">{row.especialidadeidade ? row.especialidadeidade : 'Não cadastrado'}</TableCell>
                      <TableCell align="left">{row.dataExpiracao ? convertDataD(row.dataExpiracao) : 'Não cadastrado'}</TableCell>
                      <TableCell align="center">
                        <Button variant="contained" color="primary" className="color-primary btn-edit-licenca" onClick={() => alterarExpiracao(row.codigo,row.dataExpiracao)}>
                          Editar licença
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Resultados por página"
          page={paginaAtual}
          onChangePage={handleChangePage}
        />
      </Paper>
      :  <div className="div-load-lista-modelos"><CircularLoad/></div>
      :''}
      </div>
      :
      <div className="historico-user-semUsuario">
        <h1>Nenhum usuário resgistrado até o momento.</h1>
      </div>
}
</ThemeProvider>
    <Modal 
      open={openmodalEditar}
      data={dataExpiracao}
      id={idEditar}
      handleModal={handleModalEditar}
      load={load}
      close={close}
    />
    </div>
  );
}