import { FormatColorReset } from "@material-ui/icons";
import React, { createContext, useState, useContext } from "react";

const CountContext = createContext();

export default function CountProvider({ children }) {
    const [count, setCount] = useState('tste');//teste
    const [agendadoData, setAgendadoData] = useState();
    const [secaoFormulario, setSecaoFormulario] = useState(1);
    const [procedimentos, setProcedimentos] = useState();
    const [statusTabela, setStatusTabela] = useState(false);
    const [listaBloqueado, setListaBloqueado] = useState(false);
    const [att, setAtt] = useState(false);

    //prontuarios criação de components
    const [sessaostate, setSessaostate] = useState([]);
    const [montagemstate, setMontagem] = useState([]);
    const [components, setComponents] = useState([]);

    const [idComponentAtual, setIdcomponentAtual] = useState();
    const [tituloSessaoAtual, setTituloSessaoAtual] = useState('Título da seção prontuário');//importante prontuario

    //edicao prontuario
    const [idEditProntuario, setIdEditProntuario] = useState(false);
    const [MontagemProntuario, setMontagemProntuario] = useState();
    const [PosProntuario, setPosProntuario] = useState(0);//edita posicao do array de montagem do prontuario atual
    const [Answer, setAnswer] = useState([])   //array resposta prontuarios end point sendAnswer
    const [respEdit, setrespEdit] = useState(false)   //edit respostas prontuarios
    const [AnswerEdit, setAnswerEdit] = useState([])   //ARRAY EDICAO verificar  se esta sendo usado ainda mauro
    const [respView, setRespView] = useState(false) //HABILITA  tela visualizacao prontuarios

    //Cadastro
    const [isEditPatient, setIsEditPatient] = useState();
    const [ViewPatient, setViewPatient] = useState();

    //VARIAVEL DE CONTROLE ATENDENTE ATT
    const [attatendente, setAttatendente] = useState(false);
    const [attAgenda, setAttAgenda] = useState()

    //iniciar prontuario
    const [pos, setPos] = useState(0);


    //notificações sininhos
    const [notification, setNotification] = useState([])
    const [notfyatt, setNotatt] = useState(false)

    //notificações Tabela
    const [attTableNotificacoes, setAttTableNotificacoes] = useState(false)

    //Controle de Rotas
    const [dataRoute, setDataRoute] = useState([{ route: '/agenda', autorization: false },
     { route: '/financeiro', autorization: false },
    { route: '/pacientes', autorization: false  },
     { route: '/novo-modelo', autorization: false  },
    { route: '/relatorios', autorization: false  }])






    return (

        <CountContext.Provider
            value={{
                count,//usando para teste
                setCount, //usando para teste
                agendadoData,
                setAgendadoData,
                secaoFormulario,
                setSecaoFormulario,
                procedimentos,
                setProcedimentos,
                statusTabela,
                setStatusTabela,
                listaBloqueado,
                setListaBloqueado,
                sessaostate,
                setSessaostate,
                montagemstate,
                setMontagem,
                components,
                setComponents,
                att,
                setAtt,
                idComponentAtual,
                setIdcomponentAtual,
                tituloSessaoAtual,
                setTituloSessaoAtual,
                idEditProntuario,
                setIdEditProntuario,
                pos,
                setPos,
                attAgenda, 
                setAttAgenda,
                MontagemProntuario,
                setMontagemProntuario,
                PosProntuario,
                setPosProntuario,
                Answer,
                setAnswer,
                respEdit,
                setrespEdit,
                AnswerEdit,
                setAnswerEdit,
                respView,
                setRespView,
                isEditPatient,
                setIsEditPatient,
                ViewPatient,
                setViewPatient,
                attatendente,
                setAttatendente,
                notification,
                setNotification,
                notfyatt,
                setNotatt,
                dataRoute,
                setDataRoute,
                attTableNotificacoes, 
                setAttTableNotificacoes

            }}
        >

            {children}
        </CountContext.Provider>
    );
}

export function StateGlobal() {
    const context = useContext(CountContext);

    const { count,
        setCount,
        agendadoData,
        setAgendadoData,
        procedimentos,
        setProcedimentos,
        statusTabela,
        setStatusTabela,
        listaBloqueado,
        setListaBloqueado,
        sessaostate,
        setSessaostate,
        montagemstate,
        setMontagem,
        components,
        setComponents,
        att,
        setAtt,
        idComponentAtual,
        setIdcomponentAtual,
        tituloSessaoAtual,
        setTituloSessaoAtual,
        idEditProntuario,
        setIdEditProntuario,
        pos,
        setPos,
        MontagemProntuario,
        setMontagemProntuario,
        PosProntuario,
        setPosProntuario,
        Answer,
        setAnswer,
        respEdit,
        setrespEdit,
        AnswerEdit,
        setAnswerEdit,
        respView,
        setRespView,
        secaoFormulario,
        setSecaoFormulario,
        isEditPatient,
        setIsEditPatient,
        attAgenda, 
        setAttAgenda,
        ViewPatient,
        setViewPatient,
        attatendente,
        setAttatendente,
        notification,
        setNotification,
        notfyatt,
        setNotatt,
        dataRoute,
        setDataRoute,
        attTableNotificacoes, 
        setAttTableNotificacoes


    } = context;

    return {
        count,
        setCount,
        agendadoData,
        secaoFormulario,
        setAgendadoData,
        setSecaoFormulario,
        procedimentos,
        setProcedimentos,
        statusTabela,
        setStatusTabela,
        listaBloqueado,
        setListaBloqueado,
        sessaostate,
        setSessaostate,
        montagemstate,
        setMontagem,
        components,
        setComponents,
        att,
        setAtt,
        idComponentAtual,
        setIdcomponentAtual,
        tituloSessaoAtual,
        setTituloSessaoAtual,
        idEditProntuario,
        setIdEditProntuario,
        pos,
        setPos,
        MontagemProntuario,
        setMontagemProntuario,
        PosProntuario,
        setPosProntuario,
        Answer,
        setAnswer,
        respEdit,
        setrespEdit,
        AnswerEdit,
        setAnswerEdit,
        respView,
        setRespView,
        isEditPatient,
        setIsEditPatient,
        ViewPatient,
        setViewPatient,
        attatendente,
        setAttatendente,
        notification,
        setNotification,
        notfyatt,
        setNotatt,
        dataRoute,
        setDataRoute,
        attTableNotificacoes, 
        setAttTableNotificacoes,
        attAgenda, 
        setAttAgenda 
    };
}
