import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import {useHistory} from 'react-router-dom';
import conexao from '../../../Services/api';
import ModalExcluir from '../../../Components/Modais/ModalExclusao';
import ModalEnviarNotificacao from './ModalEnviaNotificacao';
import CircularLoad from '../../../Components/Load';
import { StateGlobal } from '../../../ContextAPI/context';
import '../css/Notificacao.css';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}



function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'codigo', numeric: true, disablePadding: false, label: '#' },
  { id: 'titulo', numeric: true, disablePadding: false, label: 'Título' },
  { id: 'mensagem', numeric: true, disablePadding: false, label: 'Mensagem' },
  { id: 'acao', numeric: false, disablePadding: true, label: 'Ações' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };



  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    backgroundColor: '#F3F3F3'
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function TableHistorico() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [semResultado, setSemResultado] = React.useState(false);
  const [typeAlert, setTypeAlert] = useState('');
  const [message, setMessage] = useState('');
  const [load, setLoad] = useState(true);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [openModalEnviaNot, setOpenModalEnviaNot] = React.useState(false);
  const [notificacaoId, setNotificacaoId] = React.useState('');
  const [titulo, setTitulo] = React.useState('');
  const [descricao, setDescricao] = React.useState('');
  const [rowsTotal, setRowsTotal] = useState()
  const [atualPage, setAtualPage] = useState(0)
  const { attTableNotificacoes, setAttTableNotificacoes } = StateGlobal();
  const history = useHistory();
  const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      }
    },
  }, ptBR);
  

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
  
    setAtualPage(newPage)
    buscaNotificacao(newPage)

  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setOpenModalDelete(false);
    setAttTableNotificacoes(!attTableNotificacoes)
  }

  const handleModalEnviaNot = (value) => {
    if (value) {
      enviarNotificacao()
      
    } else {
      setOpenModalEnviaNot(false);
    }
    
    
  }

  
  function deleteNotificacao(id) {
    setTitulo('Exclusão de Modelo de Notificação');
    setDescricao('Deseja excluir esse modelo?');
    setOpenModalDelete(true);
    setNotificacaoId(id)
  }

  function enviaNotificacao(id) {
    setTitulo('Enviar Notificação');
    setDescricao('Deseja enviar essa notificação para os usuários do sistema?');
    setOpenModalEnviaNot(true);
    setNotificacaoId(id)
  }
  
  async function enviarNotificacao(){
    await conexao.post(`/adm/notification/send`, 
    {
      "notification_message_id": notificacaoId
    }).
    then((response)=>{ 
      setTypeAlert('success');
        setMessage('Modelo de notificação enviado com sucesso');
        setAttTableNotificacoes(!attTableNotificacoes)
        setOpenModalEnviaNot(false);
        setTimeout(() => {
          setTypeAlert('');
          setMessage('');
        }, 1500);


    }).catch((error)=>{
      setTypeAlert('error');
      setMessage('Erro ao enviar a notificação o modelo, tente novamente');
      setOpenModalEnviaNot(false);
      setTimeout(() => {
        setTypeAlert('');
        setMessage('');
      }, 1500);
    })
  }

  async function deletaNotificacao() {
    setLoad(true)
    const id = notificacaoId
    await conexao.delete(`/adm/notification/message/${id}`)
      .then((response) => {
        setLoad(false)
        setOpenModalDelete(false);
        setTypeAlert('success');
        setMessage('Modelo de notificação deletado com sucesso');
        setAttTableNotificacoes(!attTableNotificacoes)
        setTimeout(() => {
          setTypeAlert('');
          setMessage('');
        }, 1500);
      }).catch((error) => {
  
        setLoad(false)
        setOpenModalDelete(false);
        setTypeAlert('error');
        if(!error.response.data.message){
          setMessage('Erro ao deletar o modelo, tente novamente');
        }else{
          setMessage(error.response.data.message);
        }
        setTimeout(() => {
          setTypeAlert('');
          setMessage('');
        }, 1500);
      })
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);



  async function buscaNotificacao(pag){
    let pagina
    setLoad(true)
    
    if(pag){
      pagina = pag + 1 
    }else{
      pagina = await page + 1 
    }

     const data = {
          search: search
      }
    await conexao.post(`/adm/notification/message/list?page=${pagina}`, data)
      .then((response) => {
        if(response.data.data.data.length>0){
          setSemResultado(false)
        const itens = []
        setLoad(false)
        setRowsTotal(response.data.data.total)
        setRowsPerPage(response.data.data.per_page)
        setAtualPage(response.data.data.current_page-1)
        setMessage('')
        setTypeAlert('')
    
        response.data.data.data.forEach(function(item, index){
          itens.push(
            {
            codigo: item.id,
            titulo: item.title,
            mensagem: item.message,
            })
        })
      setRows(itens)
        }else{
          setLoad(false)
          setSemResultado(true)
          setRowsTotal('0')
          setRowsPerPage('0')
          setAtualPage('0')
          setMessage('Nenhum resultado encontrado')
          setTypeAlert('error')
        }
      
    }).catch((error)=>{
      setLoad(false)
      setSemResultado(true)
      setRowsTotal('0')
      setRowsPerPage('0')
      setAtualPage('0')
      setMessage('Nenhum resultado encontrado')
      setTypeAlert('error')
    })
  }

  
  useEffect(() => {
    buscaNotificacao()
  }, [attTableNotificacoes]);

  useEffect(() => {
    buscaNotificacao()
},[]);

useEffect(() => {
  if(search===''){
    buscaNotificacao()
  }

},[search]);

const handleModal = (value) => {
  if (value) {
    deletaNotificacao()
  } else {
    handleClose()
  }
};

const busca = (event) => {
  if (event.key === 'Enter') {
    setLoad(true)
    buscaNotificacao()
  } if (event._reactName === 'onClick') {
    setLoad(true)
    buscaNotificacao()
  }
  return true
}

  return (
    <div className={classes.root}>
         <ThemeProvider theme={theme}>
    
      <div className="tableNot">
      <div className="historico-notificacao-envolve-busca">
        <TextField className="buscaHistorico"
          id="outlined-basic" 
          value={search}
          onChange={(ev) => setSearch(ev.target.value)} 
          onKeyDown={busca}
          label="Buscar por título"  
          color="primary"  
          variant="outlined" 
          InputProps={{
            endAdornment: <InputAdornment position="start"><SearchIcon onClick={(event) => busca(event)} className="iconSearchColor"/></InputAdornment>,
        }}/>
        </div>
        <div className="historico-notificacao-div-msg">
            {message !== ''?
              <Alert className="messagensFixed" variant="filled" severity={typeAlert}>
                 {message}
              </Alert>
            :''}
        </div>
        {!semResultado ?
        !load ? 
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={clsx(classes.table,"historico-notificacao-table")} 
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.codigo);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.codigo)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.codigo}
                      selected={isItemSelected}
                    >
                      <TableCell align="center" component="th" id={labelId}>
                        {row.codigo}
                      </TableCell>
                      <TableCell align="center">{row.titulo}</TableCell>
                      <TableCell align="justify">{row.mensagem}</TableCell>
                      <TableCell align="center" className="btnNotificacoes">
                        <Button variant="contained" color="primary" className="color-primary btn-edit-licenca" onClick={() => enviaNotificacao(row.codigo)}>
                          Enviar notificação
                        </Button>
                        <Button variant="contained" color="secondary" className="color-secondary btn-edit-licenca btn-excluir-btn" onClick={() => deleteNotificacao(row.codigo)}>
                          Excluir modelo 
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15]}
          component="div"
          count={rowsTotal}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Resultados por página"
          page={atualPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      :<div className="div-load-lista-modelos"><CircularLoad/></div>
      :''}
      </div>
</ThemeProvider>
        <ModalExcluir
            handleModal={handleModal}
            open={openModalDelete}
            load={load}
            titulo={titulo}
            descricao={descricao}
        />
        <ModalEnviarNotificacao
            handleModal={handleModalEnviaNot}
            open={openModalEnviaNot}
            load={load}
            titulo={titulo}
            descricao={descricao}
        />
    </div>
  );
}