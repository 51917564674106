import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { TextField, MenuItem, Select, InputLabel, FormControl } from '@material-ui/core/';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { StateGlobal } from '../../../ContextAPI/context';
// import DadosPessoais from '../Components/DadosPessoais';
import conexao from '../../../Services/api';
import './style.css'
import Modal from './Modal'
import ModalCategoria from './ModalCategoria'
import ModalNovorecebimento from './ModalNovoRecebimento'
import { currency } from '../../../Components/ReduxTeste/Validate'


const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh !important",
    overflow: "auto"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  colorPaperWhite: {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 0px 0px gray",
    padding: '2rem'
  },
  envolveResumo: {
    marginTop: '3rem',
    height: '87vh',
    padding: '2rem',



  },
  envolveDadosPaciente: {
    backgroundColor: 'white',
    padding: '2rem',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  divEnvolveFotoDados: {
    display: 'flex'
  },
  divEnvolveTextos: {
    '& p': {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: '1rem',
      color: '#8a8a8a'
    }
  },
  avatar: {
    width: '60px',
    height: '60px',
    fontSize: '2rem'
  },
  tituloName: {
    fontSize: '1.3em',
    color: '#27b8d2 !important',
    marginBottom: '.3rem !important',
    fontWeight: '600'
  },
  tituloEtapa: {
    fontSize: '1.3em',
    color: '#757575 !important',
    marginBottom: '1rem !important',
    fontWeight: '600'
  },
  filtroData: {
    display: 'flex',
  },
  filtroResulo: {
    backgroundColor: 'white',
    marginTop: '2rem',
    padding: '2rem',
    height: '50rem',
    overflow: 'auto'
  },
  pSelecionarData: {
    marginRight: '1rem',
    color: '#757575',
    fontWeight: '500'
  },
  pate: {
    marginRight: '1rem',
    color: '#757575',
    fontWeight: '500',
    marginLeft: '10px'
  },
  textField: {
    marginLeft: '2rem',
    marginRight: '2rem'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  table: {
    boxShadow: '0px 0px 0px 0px grey',
    marginTop: '2rem',
  }
  ,
  topo: {
    display: 'flex',
    padding: '2rem',
    justifyContent: 'space-between',


  },
  buttonstopdireito: {
    marginRight: '10px'

  },
  select: {
    //  marginLeft:'10px'




  },
  formControl: {
    minWidth: 50,
    width: '180px',
    marginRight: '20px'

  },

}));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#27b8d2',
      contrastText: '#fff',
    }
  },
}, ptBR);

function createData(data, hora, prontuario) {
  return { data, hora, prontuario };
}


export default function ResumoPaciente() {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [dataInit, setDataInit] = useState();
  const [dataFim, setDataFim] = useState();
  const [iddelete, setIddelete] = useState();
  const [open, setOpen] = useState(false);
  const [dataarr, setDataarr] = useState();
  const [categoria, setCategoria] = useState('');
  const [opencategoria, setOpencategoria] = useState(false);
  const [openrecebimento, setOpenrecebimento] = useState(false);
  const [edit, setEdit] = useState({ open: false, data: '' });

  const [arrCategory, setArrcategory] = useState([])

  const { pos, setPos, setMontagemProntuario, respEdit, setrespEdit, setAnswer, setRespView } = StateGlobal();


  // useEffect(() => {
  //   getHistorico()

  // }, [])


  useEffect(() => {
    getHistorico()

  }, [open, opencategoria, openrecebimento])

  function cleanEdit() {
    setEdit({ open: false, data: '' })
  }






  function getHistorico() {
    conexao.post('finance/filter/' + localStorage.getItem('userId')).then((res) => {
      if (res.data.data) {
        setRows(res.data.data)
        setDataarr(res.data.data)
      }
    }).catch((error) => {
    })

if(arrCategory === []){
    conexao.get('/finance/category/get/' + localStorage.getItem('userId')).then((res) => {
      setArrcategory(res.data.data)

    }).catch((error) => {
    })
  }

  }

  function closemodal() {
    setOpen(false)
  }
  function closemodalcategoria() {
    setOpencategoria(false)
  }
  function closemodalrecebimento() {
    setOpenrecebimento(false)
  }


  function deleteFinanceiro() {
    conexao.delete('/finance/' + iddelete).then((res) => {


    }).catch((error) => {

    })

    getHistorico()
  }








  function alterarData(data) {

    const arr = data.split(" ")

    const date = arr[0].split('-')
    const dateRev = date.reverse()

    const formatDate = `${dateRev[0]}/${dateRev[1]}/${dateRev[2]}`

    const hora = arr[1].split(":")
    const formatHora = `${hora[0]}:${hora[1]}`

    return [formatDate, formatHora]

  }


  async function filtro() {
 

    if (dataInit !== '' && dataFim !== '' ) {

      await conexao.post('/finance/filter/' + localStorage.getItem('userId'), {
        "started_at": dataInit,
        "finished_at": dataFim,
        "finance_category_id": categoria
      })
        .then((resp) => {

 
          setRows(resp.data.data)

        }).catch((error) => {
     

          setRows('')



        })
    } if (dataInit === '' && dataFim === "" && categoria !== '') {

      await conexao.post('/finance/filter/' + localStorage.getItem('userId'), {

        "finance_category_id": categoria
      })
        .then((resp) => {





          setRows(resp.data.data)

        }).catch((error) => {


          setRows('')



        })

    }



  }


  return (
    <div className={classes.envolveResumo}>
      <ThemeProvider theme={theme}>
        <div className={classes.topo}>
          <h3 className={classes.tituloEtapa}>Financeiro</h3>
          <div>
            <Button className={classes.buttonstopdireito} variant="contained"  color="primary" onClick={() => {
              setOpencategoria(true)
            }}>Cadastrar categoria</Button>
            <Button onClick={() => {
              setOpenrecebimento(true)
              setEdit({ open: false, data: '' })
            }} variant="contained" className="color-primary" color="primary">Novo recebimento</Button>
          </div>
        </div>
        <div>
          {/* <DadosPessoais /> */}
          <div className={classes.filtroResulo}>
            <div className={classes.filtroData}>
              <p className={classes.pSelecionarData}>Filtrar por:</p>
              <form className={classes.container} noValidate>

                <TextField
                  id="date"
                  label="Data inicial"
                  type="date"
                  onChange={(e) => { setDataInit(e.target.value) }}
                  value={dataInit}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <p className={classes.pate}>até</p>
                <TextField
                  id="date"
                  label="Data final"
                  type="date"
                  value={dataFim}
                  className={classes.textField}
                  onChange={(e) => { setDataFim(e.target.value) }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {/* <p className={classes.pSelecionarData}>Tipo de despesa:</p> */}
                {/* <TextField variant='outlined' select label='Tipo de despesa' value={despesa} onChange={(e) => { setDespesa(e.target.value) }} value={despesa} className={classes.textField}>
                  <MenuItem key={1} value='PAGAMENTO'>Pagamento</MenuItem>
                  <MenuItem key={2} value='RECEBIMENTO'>Recebimento</MenuItem>
                </TextField> */}
                <FormControl>
                  <InputLabel className={classes.select} id="demo-simple-select-helper-label">TIPO:</InputLabel>

{/* {categoria} */}

                  <Select
                    labelId="demo-simple-select-helper-label"
                    className={classes.formControl}
                    value={categoria}
                    onChange={(e) => { setCategoria(e.target.value) }}
                  >
                        
                    {arrCategory && arrCategory.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                      )

                    })}
                     <MenuItem key={'vazio'} value={''}>
               
                     </MenuItem>




                  </Select>
                  {/* {categoria ? categoria+'true': categoria+'false' } */}
                </FormControl>
                <Button variant="contained" className="color-primary" color="primary" onClick={() => { filtro() }}>
                  Filtrar
                            </Button>
                <Button style={{ marginLeft: '10px' }} variant="contained" className="color-primary" color="primary" onClick={() => {
               
                  setDataInit('')
                  setDataFim('')
                                 
                 getHistorico()
             
                  setCategoria('')   
                 

                }}>
                  Limpar
                            </Button>
              </form>
            </div>
            <div>
              <TableContainer className={classes.table} component={Paper}>
                <Table size="small" aria-label="tabela resumo">
                  <TableHead>
                    <TableRow>
                      <TableCell>Vencimento</TableCell>
                      <TableCell align="center">Descrição</TableCell>
                      <TableCell align="center">Valor</TableCell>
                      <TableCell align="center">Forma de Pagamento</TableCell>
                      <TableCell align="center">Categoria</TableCell>
                      <TableCell align="center">Status</TableCell>

                      <TableCell align="center"></TableCell>

                    </TableRow>
                  </TableHead>


                  <TableBody>

                    {

                      rows ?
                        rows.map((row) => (
                          <TableRow key={row.data}>
                            <TableCell component="th" scope="row">{alterarData(row.due_date)[0]}
                            </TableCell>
                            <TableCell align="center">{row.description}</TableCell>
                            <TableCell align="center">R$ {currency(row.value)}</TableCell>
                            <TableCell align="center">{row.payment_type}</TableCell>
                            <TableCell align="center">{row.finance_category.name}</TableCell>
                            <TableCell align="center">{row.status}</TableCell>
                            <TableCell align="center">

                              <IconButton onClick={() => {
      
                                conexao.get('/finance/get/' + row.id).then((res) => {
                           
                                  setEdit({ open: true, data: res.data.data[0] })
                                  setOpenrecebimento(true)
                                }).catch((error) => {
                          
                                })


                              }}>
                                <EditIcon />
                              </IconButton>
                              <IconButton onClick={() => {
                                setOpen(true)
                                setIddelete(row.id)

                              }}>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                        :
                        <TableCell colSpan={5} className="erroDados">Registros não encontrados</TableCell>
                    }
                  </TableBody>

                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </ThemeProvider>
      <Modal desc={'Tem certeza que deseja deletar?'} titulo={'Financeiro'} delete={deleteFinanceiro} open={open} close={closemodal} />
      {opencategoria ? 'true' : 'false'}
      <ModalCategoria opencategoria={opencategoria} close={closemodalcategoria} />
      <ModalNovorecebimento openrecebimento={openrecebimento} close={closemodalrecebimento} edit={edit} cleanedit={cleanEdit} />
    </div>

  );
}