import React, { useState, useEffect } from 'react';
import ListIcon from '@material-ui/icons/List';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import '../../css/NovoModelo.css';
import { StateGlobal } from '../../../../../ContextAPI/context'


export default function CardsSecoesSalvas({ id, montagemsessao, titulosessao }) {



  const { sessaostate,
    setSessaostate,
    montagemstate,
    setMontagem,
    components,
    setComponents,
    setAtt,
    idComponentAtual,
    setIdcomponentAtual,
    att,
    tituloSessaoAtual,
    setTituloSessaoAtual } = StateGlobal();

  const [titulo, setTitulo] = useState()
  const [index, setIndex] = useState()


  useEffect(() => {

    sessaostate.map((item, index) => {

      if (item.id === id) {

        setIndex(index)


        setTitulo(item.titulosessao)



        //setTituloSessaoAtual(item.titulosessao)


      }

    })
  }, [idComponentAtual])


  // useEffect(()=>{
  //   setTituloSessaoAtual(titulo ? tit : 'Título da seção prontuário')

  // },[])


  function teste() {
    let sad = ''

    sessaostate.map((item, index) => {
      if (item.id === id) {

        sad = item.titulosessao
      }

    }
    )


  }



  return (
    <div onClick={() => {
      setIdcomponentAtual(id)
      sessaostate.map((item, index) => {
        if (item.id === id) {

          setMontagem(sessaostate[index].montagem)
          setTituloSessaoAtual(sessaostate[index].titulosessao)
          // setTitulo(sessaostate[index].titulosessao)
          //setTitulo(sessaostate[index].titulosessao)
        }

      })

    }} className="envolve-secao-icon">
      {/* {titulosessao} */}

      <ListIcon className="icon-list" />
      <div className="envolve-titulo-secao">

        {id === idComponentAtual ?
          <p>{tituloSessaoAtual}</p>


          : <p>{titulo}</p>
        }

        {/* {titulosessao ? <p>{titulosessao}</p> : <p>titulo da sessão</p>} */}




      </div>
      {/* <p> {titulo ? titulo : 'Titulo  da sessão'} </p> */}


      {/* {index !== 0 ?  */}
      <IconButton onClick={() => {
        sessaostate.map((item, index) => {
          if (item.id === id) {
            var arr = sessaostate

            arr.splice(index, 1)
            setSessaostate(arr)
            setAtt(!att)
          }
        })
      }}>
        <DeleteIcon />
      </IconButton>
      {/* // :''} */}
      {/* <IconButton  onClick={()=>{
                          sessaostate.map((item,index)=>{
                            if(item.id === id){
                              sessaostate[index].montagem = montagemstate
                            }

                          })
                        }}>
                            <SaveIcon/>
                        </IconButton> */}
    </div>

  );
}