import React, {useState} from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Logo from '../../Assets/Images/LogoCP.svg';
import FormLogin from './Form';
import ModalEsqueciSenha from './ResetResenha';
import './css/login.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
});

export default function Login() {
  const [openEsqueci, setOpenEsqueci] = useState(false);

  const closeModalEsqueci = () => {
    setOpenEsqueci(false);
  }

  return (
       <ThemeProvider theme={theme}>
        <div className="Login-background-image">
            <div className="Login-card-form-login">
              <img src={Logo} className="Login-logo"/>
              <p className="p-adm">Administrador</p>
                  <FormLogin/>
                  <div className="esqueci-senha-div">
                     <Button 
                        href="#text-buttons" 
                        onClick={()=>setOpenEsqueci(true)}
                        className="Login-esqueci-senha" 
                        color="default">
                        Esqueci minha senha
                      </Button>
                  </div>
            </div>
            <ModalEsqueciSenha open={openEsqueci} handleModal={closeModalEsqueci}/>
        </div>
      </ThemeProvider>
  );
}