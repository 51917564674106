import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import CircularLoad from '..';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { ptBR } from '@material-ui/core/locale';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { StateGlobal } from '../../../ContextAPI/context'

import conexao from '../../../Services/api'
import FormHelperText from '@material-ui/core/FormHelperText';
import { validateEmail, validatePasswordLogin, validateName } from '../../Login/Form/validate';
import Alert from '@material-ui/lab/Alert';
import {campovazio} from '../../../Components/ReduxTeste/ValidateForm'


import './style.css'

const useStyles = makeStyles((theme) => ({
    input:{
      width: '100%',
      marginTop: '0.5rem',
      marginBottom: '0.8rem',
      display: 'flex',
      backgroundColor: 'white',
    },
    IconButton: {
        '& .MuiDialogActions-root-1334':{
         justifyContent: 'center !important',
    }
    },
    
}));

const theme = createMuiTheme({
    palette: {
      secondary: {
        light: '#E7366A',
        main: '#E7366A',
        dark: '#D93364',
        contrastText: '#fff',
      },
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      },
    },
  }, ptBR);

export default function Modal(props) {
    const classes = useStyles();
    const [load, setLoad] = useState()
    const [open, setOpen] = useState(props.open)
    
    const [nome, setNome] = useState(undefined);
    const [email, setEmail] = useState("");

    const [dataarr, setDataarr] = useState();
    const [atendentes, setAtendentes] = useState([]); 
    const [openalert, setOpenalert] = useState({ open: false })

    
    const[changepassword,setChangepassword] =useState(false);

    const [validatesx, setvalidatesx] = useState([
      { name: 'Name', funcao: campovazio, error: false , value:'' }
      , { name: 'Email', funcao: campovazio, error: false ,value:''},
      { name: 'password', funcao: campovazio, error: false ,value:''}
    ]);
  
  
  const initialvalidate =[
    { name: 'Name', funcao: campovazio, error: false , value:'' }
    , { name: 'Email', funcao: campovazio, error: false ,value:''},
    { name: 'password', funcao: campovazio, error: false ,value:''}
  ]
  
  function onchangeform(e) {
    const { name, value } = e.target
    validatesx.map((item) => {
      if (item.name === name) {
        item.error = item.funcao(value)
        item.value = value
      }
    })
    const arr = validatesx.slice()
    setvalidatesx(arr)
  }
    

    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
      });

      
      const {
        attatendente,
        setAttatendente
        
       
      } = StateGlobal();

   
    
     function closeModal(){

     

        setOpen(false);  
        props.close()
        
     
       
  
      }

    useEffect(() => {
      setNome(undefined)
      setEmail('')
      setValues({ ...values, ['password']: '' })
      if(props.open === 'edit' || props.open === 'visible'){
        setNome(attatendente.name)
        setEmail(attatendente.email)
        setValues({ ...values, ['password']: '' })

        initialvalidate[0].value =  attatendente.name
        initialvalidate[1].value =  attatendente.email
         
        
      }
      
        setOpen(props.open)    
        setChangepassword(false)
     
    }, [props.open])
     
    useEffect(() =>{


  },[props.open])



    useEffect(() => {

      setvalidatesx(initialvalidate)


      if(props.open === 'visible'){

      }

     
    }, [props.open])


    function Alertshow(msg, type) {
      setOpenalert({ open: true, msg: msg, type: type })
      setTimeout(() => {
        setOpenalert({ open: false })
      }, 2000);
    }


    function createAtendente(){


      
    validatesx.map((item)=>{
      
      if(item.value === ''){

        item.error = true
        
      }})
      const arr = validatesx.slice()
      setvalidatesx(arr)

      

      if(props.open === 'edit'){

        if(changepassword && validatePasswordLogin(values.password !== '')){

          conexao.put('/employee/update/'+props.idedit, {name : nome  , email : email , senha: values.password }).then((res)=>{
            Alertshow('Editado com sucesso!', 'success')
            setAttatendente(!attatendente)
          }).catch((error)=>{
            Alertshow('Erro ao editar!', 'error')
          })

        }else{

        

        conexao.put('/employee/update/'+props.idedit, {name : nome  , email : email }).then((res)=>{
          Alertshow('Editado com sucesso!', 'success')
          setAttatendente(!attatendente)
        }).catch((error)=>{
          Alertshow('Erro ao editar!', 'error')
        })
      }



      }else{

      conexao.post('/employee/create',{ name : nome , email : email , password : values.password , doctor_id : localStorage.getItem('doctorId') }).then((res)=>{
        Alertshow('Criado com sucesso!', 'success')
        //setAttatendente(!attatendente)

      }).catch((error)=>{
        Alertshow('Erro ao cadastrar!', 'error')
      })
    }
    }


  
    

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    
      };
    
      const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    return (
      
            <ThemeProvider theme={theme}>
            <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <div className='tamanho-modal'>
                <DialogTitle id="alert-dialog-title">

                    <div className='title-atendente'> Cadastro de Atendente
                <div className='title-fechar'><CloseIcon onClick={()=>{closeModal()}}/>
                
                </div>
                </div>

                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                  {/* {validateName(nome) ? 'nome preenchidoo' : 'VAZIOOOO'}  */}

                  {openalert.open ?
                <div className="Modal-agendamento-alert">
                  <Alert variant="filled" severity={openalert.type}>
                    {openalert.msg}
                  </Alert>
                </div>
                : ''}
            <TextField
              className={classes.input} 
              name='Name'
              value={nome} 
              placeholder="Digite o seu nome"
              onChange={(event) => {
                setNome(event.target.value);
                onchangeform(event)
              }}           
             error={validatesx[0].error}
             helperText={validatesx[0].error ? 'este campo precisa ser preenchido': ''}
              type="text" 
              required
              id="" 
              label="Nome" 
              variant="outlined"
         
              />

            <TextField            
              className={classes.input} 
              name='Email' 
              value={email} 
              placeholder="Digite o seu e-mail"
              onChange={(event) => {
                setEmail(event.target.value);
                onchangeform(event)
              }} 
              error={validatesx[1].error}
              helperText={validatesx[1].error ? 'este campo precisa ser preenchido': ''}
              type="text" 
              required
              id="" 
              label="E-mail" 
              variant="outlined"
              
                    
            />
             
{props.open === 'edit' ?  
<Button className='title-atendente' onClick={()=>{
  setChangepassword(!changepassword)
}}>Deseja redefinir sua  senha  de atendente click aqui</Button>
: ''}

{props.open !== 'visible' && props.open !== 'edit' ?
<FormControl  variant="outlined"  style={{width: '100%' }}
error={validatesx[2].error}>
          <InputLabel htmlFor="outlined-adornment-password" required>Senha</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            required
            name='password'
            onChange={ (e)=>{
              onchangeform(e)
              setValues({ ...values, ['password']: e.target.value })
          
          }}
            endAdornment={
              <InputAdornment position="end">
                <div className='teste'>
                <IconButton
                className={classes.IconButton}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
                </div>
              </InputAdornment>
            }
            labelWidth={55}
          />
          <FormHelperText id="component-error-text">{validatesx[2].error ? 'este campo precisa ser preenchido': ''}</FormHelperText>
        </FormControl>

        :""}
{ changepassword ===  true ?
<FormControl  variant="outlined"  style={{width: '100%' }}
error={validatesx[2].error}>
          <InputLabel htmlFor="outlined-adornment-password" required>Senha</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            name='password'
            required
            onChange={ (e)=>{
              onchangeform(e)
              setValues({ ...values, ['password']: e.target.value })
          
          }}
            endAdornment={
              <InputAdornment position="end">
                <div className='teste'>
                <IconButton
                className={classes.IconButton}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
                </div>
              </InputAdornment>
            }
            labelWidth={55}
          />
          <FormHelperText  id="component-error-text">{validatesx[2].error ? 'este campo precisa ser preenchido': ''}</FormHelperText>
        </FormControl>

        :''}

                    </DialogContentText>
                </DialogContent>

                    <DialogActions>
                        <Button 
                         onClick={()=>{closeModal()}}
                          variant="contained"
                          color="secondary"
                          className="color-secondary"
                          >
                            Cancelar
                            
                        </Button>


                        {props.open !== 'visible' ?
                        <Button onClick={() => {                
                          
                        createAtendente()
                        
                        //closeModal()

                        // getallAtendents()

                        }} variant="contained" className="color-primary" color="primary" autoFocus>
                            {props.open !== 'edit'? 'Inserir' : 'editar'}
                        </Button>:""}
                    </DialogActions>
                    </div>
                </Dialog>
            </ThemeProvider>
        
    );
}