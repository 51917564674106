import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import CircularLoad from '../../../../Components/Load';
import api from '../../../../Services/api'
import {useHistory} from 'react-router-dom';
import './css/HistoricoNotificacao.css'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}



function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'codigo', numeric: true, disablePadding: false, label: '#' },
  { id: 'dataEnvio', numeric: false, disablePadding: false, label: 'Data de envio' },
  { id: 'titulo', numeric: false, disablePadding: false, label: 'Título' },
  { id: 'mensagem', numeric: false, disablePadding: false, label: 'Mensagem' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };



  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    backgroundColor: '#F3F3F3'
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function TableHistorico() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [dataInicial, setDataInicial] = React.useState('');
  const [dataFinal, setDataFinal] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [typeAlert, setTypeAlert] = useState('');
  const [semHistorico, setSemHistorico] = React.useState(false);
  const [semResultado, setSemResultado] = React.useState(false);
  const [idEditar, setIdEditar] = React.useState('');
  const [totalPage, setTotalPage] = useState();
  const [pageQuant, setPageQuant] = useState()
  const [atualPage, setAtualPage] = useState(0)
  const [load, setLoad] = useState(true)
  const history = useHistory();
  const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      }
    },
  }, ptBR);
  

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
  
    setAtualPage(newPage)
    buscaHistorico(newPage)

  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  function maskData(data){
    data = data.split('-')
    data = `${data[2]}/${data[1]}/${data[0]}`
    return data
  }

 
  useEffect(() => {
    buscaHistorico();
  }, []);


  async function buscaHistorico(pag){
    let pagina
    setLoad(true)
    
    if(pag){
      pagina = pag + 1 
    }else{
      pagina = await page + 1 
    }

      const data ={
        "search": "",
        "created_at" : dataInicial,
        "finished_at" : dataFinal
      }
    await api.post(`/adm/notification/history?page=${pagina}`, data)
      .then((response) => {
        const itens = []
          setTotalPage(response.data.data.total)
          setPageQuant(response.data.data.per_page)

          setRows([])
          setSemResultado(false)
          setMessage('');
          setLoad(false)
        response.data.data.data.map(function(item, index){
          itens.push(
            {
            codigo: index+1,
            dataEnvio: item.date,
            titulo: item.title,
            mensagem: item.message,
            })
        })
    setRows(itens)
      }).catch((error)=>{
        setLoad(false)
      })
      
  }

  function LimpaHistorico(){
    setDataInicial('')
    setDataFinal('')
    setSearch('')
    buscaHistorico()
  }

  useEffect(() => {
    buscaHistorico(search)
  }, [search]);

  const capturaDataFinal = (prop) => (event) => {
    setDataFinal(event.target.value);
};

const capturaDataInicial = (prop) => (event) => {
  setDataInicial(event.target.value);
};


  return (
    <div className={classes.root}>
         <ThemeProvider theme={theme}>
      {!semHistorico ?
      <div className="tableuser">
      <div className="historico-historico-envolve-busca">
        <div className="historico-historico-input-data">
        <TextField className="date"
          id="outlined-basic" 
          value={dataInicial}
          onChange={capturaDataInicial()}
          label="Data inicial"  
          color="primary"  
          variant="outlined" 
          type="date" 
          maxLength = "10"
          inputProps ={{maxLength:10}}
          InputLabelProps={{
            shrink: true,
          }}
         />
        <TextField className="date"
          id="outlined-basic" 
          value={dataFinal}
          label="Data final"
          onChange={capturaDataFinal()}  
          color="primary"  
          variant="outlined"
          type="date" 
          maxLength = "10"
          inputProps ={{maxLength:10}}
          InputLabelProps={{
            shrink: true,
          }}
         />
          <div className="envolve-btn-filtro-user">
              <Button variant="contained" color="primary" className="color-primary" onClick={buscaHistorico}>
                Filtrar data
              </Button>
              <Button variant="contained" color="secondary" className="color-secondary" onClick={LimpaHistorico}>
                Limpar filtro
              </Button>
          </div>
        </div>
        </div>
        <div className="historico-historico-div-msg">
            {message !== ''?
              <Alert className="messagensFixed" variant="filled" severity={typeAlert}>
                 {message}
              </Alert>
                  :''}
        </div>
        {!semResultado ?
          !load ? 
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={clsx(classes.table,"historico-historico-table")} 
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.codigo);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.codigo)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.codigo}
                      selected={isItemSelected}
                    >
                      <TableCell align="center" component="th" id={labelId}>
                        {row.codigo}
                      </TableCell>
                      <TableCell align="center">{row.dataEnvio ? maskData(row.dataEnvio) : 'Não cadastrados'}</TableCell>
                      <TableCell align="center">{row.titulo}</TableCell>
                      <TableCell align="justify">{row.mensagem}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15]}
          component="div"
          count={totalPage}
          rowsPerPage={pageQuant}
          labelRowsPerPage="Resultados por página"
          page={atualPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      : <div className="div-load-lista-modelos"><CircularLoad/></div>
      :''}
      </div>
      :
      <div className="historico-historico-semHistorico">
        <h1>Nenhum histórico de notificações resgistrado até o momento.</h1>
      </div>
}
</ThemeProvider>
    </div>
  );
}