import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularLoad from '../Load';
import DialogContentText from '@material-ui/core/DialogContentText';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import conexao from '../../Services/api'
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
    width: '100%',
    },
    divEnvolveForm:{
        display: 'flex',
        flexDirection: 'column',
        margin: '0rem',
        '& > *': {
            marginBottom: theme.spacing(3),
            width: '100%',
        },
    }
  }));

  const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      }
    },
  });

export default function ModalSuporte(props) { 
    const classes = useStyles();
    const [load, setLoad] = useState(props.load);
    const [openModalSuporte, setOpenModalSuporte] = React.useState(props.open);
    const [mensagem, setMensagem] = React.useState('');
    const [mensagemResponse, setMensagemResponse] = React.useState('');
    const [assunto, setAssunto] = React.useState('');
    const [validaMensagem, setValidaMensagem] = React.useState(false);
    const [validaAssunto, setValidaAssunto] = React.useState(false);

    useEffect(() => {
        setLoad(props.load);
        setOpenModalSuporte(props.open);
    }, [props])


    function handleExclusao(value){
        props.handleModal(value)
        setMensagemResponse('')
    }

    function onChangeAssunto(event){
        setAssunto(event.target.value);
    }

    function onChangeMensagem(event){
        setMensagem(event.target.value);
    }

    async function enviaSuporte(event){
        event.preventDefault();
        var userId = '';
        setLoad(true)
        if(mensagem === '' || assunto === ''){
            setLoad(false)
            if(mensagem === ''){
                setValidaMensagem(true);
            }else{
                setValidaMensagem(false); 
            } if(assunto === ''){
                setValidaAssunto(true);
            }else{
                setValidaAssunto(false);
            }
        }else{
            setValidaMensagem(false);
            setValidaAssunto(false);
            if (localStorage.getItem('userId')) {
                userId = localStorage.getItem('userId');
                await conexao.post('/ticket/create',
                {
                    "title": assunto,
                    "message": mensagem,
                    "status": "ENVIADO",
                    "user_id": userId,
                })
                .then((response) => {
                    setLoad(false)
                    if(!response.data.error){
                        setMensagemResponse('Solicitação de suporte enviada com sucesso. Em breve nossa equipe entrará em contato.');
                    }else{
                        setMensagemResponse('Ocorreu um erro ao enviar sua solicitação de suporte! Por favor, tente novamente mais tarde.');
                    }
                }).catch((error)=>{
                    setLoad(false)
                    setMensagemResponse('Ocorreu um erro ao enviar sua solicitação de suporte! Por favor, tente novamente mais tarde.');
                })
            }
        }
    }

    return(
            <div>
                <Dialog
                    open={openModalSuporte}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Suporte técnico</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    <ThemeProvider theme={theme}>
                    <form onSubmit={enviaSuporte} className={classes.root} noValidate autoComplete="off">
                    {!mensagemResponse?
                        <div className={classes.divEnvolveForm}>
                            <TextField 
                                id="outlined-basic" 
                                label="Assunto" 
                                variant="outlined"  
                                required
                                value={assunto}
                                onChange={onChangeAssunto}
                                error={validaAssunto ?true:false}
                                helperText={validaAssunto ? 'Campo obrigatório' : ''} />
                            <TextField
                                id="outlined-multiline-static"
                                label="Mensagem"
                                multiline
                                rows={4}
                                required
                                variant="outlined"
                                value={mensagem}
                                onChange={onChangeMensagem}
                                error={validaMensagem ? true:false}
                                helperText={validaMensagem ? 'Campo obrigatório' : ''}
                            />
                        </div>
                          :
                        <div>
                            <p>{mensagemResponse}</p>
                        </div>
                    }
                        <DialogActions>
                            <Button onClick={()=>handleExclusao(false)} color="default">
                                Fechar
                            </Button>
                            {!mensagemResponse?
                                <Button type="submit" variant="contained" className="color-primary" color="primary">
                                    {!load?'Enviar':<CircularLoad/>}
                                </Button>
                            :''}
                        </DialogActions>
                    </form>
                    </ThemeProvider>
                    </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
     );
}