import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import CircularLoad from '..';
import DialogContentText from '@material-ui/core/DialogContentText';

import { ptBR } from '@material-ui/core/locale';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';

// import Alert from '@material-ui/lab/Alert';



const useStyles = makeStyles((theme) => ({
    
    // teste: {
    //     '& .MuiDialog-scrollPaper':{
    //       display: 'flex !important',
    //       justifyContent: 'center !important',
    //         flexDirection: 'column !important',
          
    // }
    // },
    
}));


const theme = createMuiTheme({
    palette: {
      secondary: {
        light: '#E7366A',
        main: '#E7366A',
        dark: '#D93364',
        contrastText: '#fff',
      },
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      },
    },
  }, ptBR);

export default function Modal(props) {

    const [load, setLoad] = useState()
    const [open, setOpen] = useState(props.open)
    const classes = useStyles();

    const [openAlert, setOpenAlert] = useState({ open: false, alert: 0 })


    useEffect(() => {

        setOpen(props.open)

    }, [props.open])



//     const alerts = [<Alert variant="filled" severity="error">Erro ao excluir atendente</Alert>,
//   <Alert variant="filled" severity="success">Atendente excluido com sucesso</Alert>
//   ]

    return (
        <div style={{width:'100%'}}>
            <ThemeProvider theme={theme} >
                <Dialog 
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{props.titulo}</DialogTitle>
                    <DialogContent >
                        <DialogContentText id="alert-dialog-description">
                            {props.desc}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            props.close()
                            setOpen(false)

                        }} variant="contained" className="color-secondary" color="secondary">
                            Cancelar
                        </Button>
                        <Button onClick={() => {                

                                props.delete()
                                props.close()                      


                        }} variant="contained" className="color-primary" color="primary" autoFocus>
                            {!load ? 'Concluir' : 'carregando ...'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>

        </div>
    );
}