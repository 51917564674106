import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TotalResultado from './components/total';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 0,
    paddingTop: 100,
    backgroundColor:'#F3F3F3',
    minHeight: '100vh',
    height: '100%'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: '100vh',
    backgroundColor: '#F3F3F3'
  },
  espacamento:{
      padding: 20,
      paddingTop: 0
  },
  container:{
    margin: 15,
    marginTop: 0
  }
}));

export default function RelatorioFinanceiro(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
      <Grid container className={classes.espacamento}>
        <Grid item xs={12}>
        
        <div className="cabecalho-personalizado">
          <h1>Relatório Financeiro</h1>
          <div>
            {/* <Button variant="contained" color="primary" className="color-primary">
              <GetAppIcon /> Exportar para Excel  
            </Button> */}
          </div>
        </div>  
        <div className="tabela-personalizada">
          <TotalResultado/>
        </div>
        </Grid>
      </Grid>
      </div>
   
    </div>
  );
}