import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularLoad from '../../../Components/Load';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import { campovazio } from '../../../Components/ReduxTeste/ValidateForm'
import conexao from '../../../Services/api'
import '../css/Cupons.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100%',
    },
    width: '100%'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '96%',
    marginBottom: '1rem'
  },
}));


export default function ModalExcluir(props) {
  const [load, setLoad] = useState(props.load);
  const classes = useStyles();
  const [openModalEditar, setOpenModalEditar] = useState(props.open);
  const [selectedDate, setSelectedDate] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [typeAlert, setTypeAlert] = useState('');

  const state = {
    code: { name: 'code', funcao: campovazio, value: '', error: false },
    discount: { name: 'discount', funcao: campovazio, value: '', error: false },
    limit: { name: 'limit', funcao: campovazio, value: '', error: false },
    expired_at: { name: 'expired_at', funcao: campovazio, value: '', error: false },
    plan_id: { name: 'plan_id', funcao: campovazio, value: '2', error: false },
  }


  const [form, setForm] = useState(state)


  useEffect(()=>{

    setForm(state)

  },[props])



  function pegadados(e) {

    const { name, value } = e
    const obj = form[name]
    const er = form[name].funcao(value)
    let att = { ...obj, error: er, value: value }

    setForm({ ...form, [name]: att })

  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    setLoad(props.load);
    setOpenModalEditar(props.open);
  }, [props])


  function handleEditar() {
    var pass = true


    const arr =  Object.values(form)
    arr.map((item)=>{   
  
      if(item.value === '' ){      
         form[item.name].error = item.funcao(item.value) 
         pass = false 
      }
     
      setForm({...form})
    })

    if(pass){
      setLoad(true);
      const data = {
        code : form.code.value,
        discount :form.discount.value,
        plan_id: 2 ,
        limit: form.limit.value,
        expired_at : form.expired_at.value
      }


      conexao.post('/adm/coupon/create',data).then((res)=>{
        setMessage('Cupom criado com sucesso!');
        setTypeAlert('success');
        setLoad(false);
        setTimeout(() => {
          setMessage('');
          setTypeAlert('');
          props.handleModal(true)
          window.location.reload()
        }, 2000);
      }).catch((error)=>{
        setLoad(false);
        setMessage('Erro ao criar cupom!');
        setTypeAlert('error');
        setTimeout(() => {
          setMessage('');
          setTypeAlert('');
          props.handleModal(true)
          window.location.reload()
        }, 2000);
      })      

    }

  }

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Dialog
          className="modal-editar-licenca"
          open={openModalEditar}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Novo cupom</DialogTitle>
          {message !== ''?
              <Alert className="messagensFixed" variant="filled" severity={typeAlert}>
                {message}
              </Alert>
          :''}
          <form className={classes.root} noValidate autoComplete="off">
            <DialogContent>
              <TextField
                id="cupom"
                label="Código do cupom"
                type="text"
                variant="outlined"
                className={classes.textField}
                name='code'
                value={form.code.value}
                onChange={(e)=>{pegadados(e.target)}}
                error={form.code.error  ? true : false}
                helperText={form.code.error ? form.code.error : ''}
              />
              <TextField
                id="desconto"
                label="% de desconto"
                type="number"
                variant="outlined"
                className={classes.textField}
                name='discount'
                value={form.discount.value}
                onChange={(e)=>{pegadados(e.target)}}
                error={form.discount.error  ? true : false}
                helperText={form.discount.error ? form.discount.error : ''}
              />
              <TextField
                id="limite"
                label="Limite de utilização"
                type="number"
                variant="outlined"
                className={classes.textField}
                value={form.limit.value}
                name='limit'
                onChange={(e)=>{pegadados(e.target)}}
                error={form.limit.error  ? true : false}
                helperText={form.limit.error ? form.limit.error : ''}
              />
              <TextField
                id="date"
                label="Data de expiração"
                type="date"
                variant="outlined"
                //onChange={handleDateChange}
                defaultValue={selectedDate}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e)=>{pegadados(e.target)}}
                name='expired_at'
                value={form.expired_at.value}
                error={form.expired_at.error ? true : false}
                helperText={form.expired_at.error ? form.expired_at.error : ''}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{props.handleModal(false)}} color="default">
                Cancelar
              </Button>
              <Button onClick={() => { handleEditar()}} variant="contained" className="color-primary" color="primary" autoFocus>
                {!load ? 'Salvar' : <CircularLoad />}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}