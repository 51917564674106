import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { StateGlobal } from '../../../../ContextAPI/context'
import VisibilityIcon from '@material-ui/icons/Visibility';
import CircularLoad from '../../../../Components/Load';
import conexao from '../../../../Services/api';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ModalConfirmacao from '../../../../Components/Modais/ModalConfirmacao';
import TablePagination from '@material-ui/core/TablePagination';
import '../css/Listagem.css';


const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    border: 'none',
    marginBottom: '2rem'
  },
  search: {
    background: '#f1f1f1',
    boxShadow: '0px 1px 10px rgba(25, 32, 56, 0.08)',
    borderRadius: '3px',
    height: '45px',
    fontSize: '15px',
    border: 'none',
    padding: '15px',
    minWidth: '350px',

  },
  iconSearch: {
    color: '#8F8F8F'
  }
}));

const theme = createMuiTheme({
  palette: {
    secondary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },
    primary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#8F8F8F',
    }
  },
}, ptBR);


export default function CardModelosCriadosetModelosCriados() {
  const classes = useStyles();
  const History = useHistory();
  const [modelosCriados, setModelosCriados] = useState([]);
  const [typeAlert, setTypeAlert] = useState();
  const [message, setMessage] = useState();
  const [load, setLoad] = useState(false);
  const [nameModelo, setNameModelo] = useState('');
  const [basemodelos, setBaseModelos] = React.useState();
  const [openModalPublicar, setOpenModalPublicar] = React.useState(false);
  const [tituloPublicar, setTituloPublicar] = React.useState('');
  const [descricaoPublicar, setDescricaoPublicar] = React.useState('');
  const [modeloId, setModeloId] = React.useState('');
  const [page, setPage] = React.useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState([15]);
  const [quantLinhas, setQuantLinhas] = useState();

  const {
    idEditProntuario,
    setIdEditProntuario
  } = StateGlobal();

  async function listaModelosAdm(pag) {
    setLoad(true)
    let pagina = ''
    if (pag) {
      pagina = pag + 1
    } else {
      pagina = 1
    }
    console.log('namemodelo dentro listamodelos', nameModelo)
    await conexao.post(`/adm/form/list?page=${pagina}`, {
      search: nameModelo,
      publish: 0
    })
      .then((response) => {
        setLoad(false)
        setModelosCriados(response.data.data.data)
        setBaseModelos(response.data.data.data)
        setRowsPerPage(response.data.data.per_page)
        setQuantLinhas(response.data.data.total)
        setPage(response.data.data.current_page - 1)
        if (response.data.data.data.length < 1) {
          setModelosCriados('')
          setTypeAlert('error');
          setMessage('Prontuário não encontrado, verifique o nome do modelo buscado.');
          setTimeout(() => {
            setTypeAlert('');
            setMessage('');
          }, 3000);
        }
      }).catch((error) => {
        setLoad(false)
        setModelosCriados('')
        setTypeAlert('error');
        setMessage('Você ainda não possui um modelo próprio. Clique em novo modelo para criar seu próprio modelo de prontuário!');
        setTimeout(() => {
          setTypeAlert('');
          setMessage('');
        }, 5000);
      })
  }

  useEffect(() => {
    listaModelosAdm()
},[]);

useEffect(() => {
  if(nameModelo===''){
    setModelosCriados([])
    listaModelosAdm()
  }

},[nameModelo]);


  useEffect(() => {
    if (nameModelo === "") {
      listaModelosAdm(false)
    }
  }, [nameModelo])



  function editeModelo(e) {

    conexao.get('/form/' + e).then((res) => {
      setIdEditProntuario({ id: e, data: res.data.data })
      History.push('/novo-modelo')
    }).catch((error) => {
    })

  }

  const busca = (event) => {
    if (event.key === 'Enter') {
      setLoad(true)
      listaModelosAdm();
      setModelosCriados([])
    } if (event._reactName === 'onClick') {
      setLoad(true)
      setModelosCriados([])
      listaModelosAdm();
    }
    return true
  }

  const handleClose = () => {
    setOpenModalPublicar(false)
  }


  const handleModalPublicar = (value) => {
    if (value) {
      publicarModelo()
    } else {
      handleClose()
    }
  };

  function despublicar(id) {
    setTituloPublicar('Publicar');
    setDescricaoPublicar('Deseja publicar esse modelo de prontuário?');
    setOpenModalPublicar(true);
    setModeloId(id)
  }

  async function publicarModelo() {
    setLoad(true)
    await conexao.post(`/adm/form/publish/${modeloId}`,
      { "publish": 1 }
    )
      .then((response) => {
        setLoad(false)
        setOpenModalPublicar(false);
        setTypeAlert('success');
        setMessage('O modelo está publicado');
        setTimeout(() => {
          setTypeAlert('');
          setMessage('');
        }, 1500);
        listaModelosAdm();
      }).catch((error) => {
        setLoad(false)
        setOpenModalPublicar(false);
        setTypeAlert('error');
        setMessage('Erro ao publicar o modelo, tente novamente');
        listaModelosAdm();
        setTimeout(() => {
          setTypeAlert('');
          setMessage('');
        }, 1500);
      })
  }

  const handleChangePage = async (event, newPage) => {

    await listaModelosAdm(newPage)

  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 15));

  };


  console.log('BASE DE MODELOS', basemodelos)
  return (
    <div className="">
      <form className={classes.root} noValidate autoComplete="off">
        <FormControl className={clsx(classes.form)}>
          <InputBase
            id="outlined-adornment-weight"
            endAdornment={<InputAdornment position="end">{<IconButton onClick={(event) => busca(event)}><SearchIcon className={classes.iconSearch} /></IconButton>}</InputAdornment>}
            labelWidth={0}
            disabled={load ? true : false}
            id='myInput'
            onKeyDown={busca}
            onChange={(e) => setNameModelo(e.target.value)}
            placeholder="Busque por um modelo"
            className={clsx(classes.search)}
          />
        </FormControl>
      </form>
      <div>
        {load ?
          <div className="div-load-lista-modelos"><CircularLoad /></div>
          : ''}
        {modelosCriados ?
          modelosCriados.map((modeloCriado) => (
            <div className="CardModelos-envolve">
              <div className="CardModelos-envolve-text-icon">
                <AssignmentIcon className="CardModelos-icon-pront" />
                <p>{modeloCriado.title}</p>
              </div>
              <div>
                <IconButton onClick={() => despublicar(modeloCriado.id)}>
                  <CloudUploadIcon />
                </IconButton>
                {/* <IconButton onClick={() => { editeModelo((modeloCriado.id)) }}>
                  <VisibilityIcon />
                </IconButton> */}
                <IconButton onClick={() => { editeModelo((modeloCriado.id)) }}>
                  <EditIcon />
                </IconButton>

              </div>
            </div>
          )) :
          <div>
            {message ?
              <div className="Modal-agendamento-alert">
                <Alert variant="filled" severity={typeAlert}>
                  {message}
                </Alert>
              </div>

              : ''}

          </div>
        }
      </div>
      {!load ?
        <TablePagination
          component="div"
          count={quantLinhas}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={[15]}
        />
        : ''}
      <ModalConfirmacao
        handleModal={handleModalPublicar}
        open={openModalPublicar}
        load={load}
        titulo={tituloPublicar}
        descricao={descricaoPublicar}
      />
    </div>
  );
}