import React, {useState, useEffect} from 'react';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import '../../../css/NovoModelo.css';
import { StateGlobal } from '../../../../../../ContextAPI/context';
import CardOdontologico from '../../../SecaoInput/Components/CardOdontologico';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';

export default function ButtonsFerramentas({index ,id}) {

    const {    
        montagemstate,
        setMontagem,
        att  ,
        setAtt,
    idComponentAtual} = StateGlobal();

        var array = []


        function getdata(e) {

        }
        


        //array.push({ component: <CardTextoCurto getData={getdata} testid={testid} tempId={id}/> })

  return (
            <div className="NovoModelo-Ferramentas">
               <div className="circle-icon">
                   <EmojiEmotionsIcon/>
               </div>
                <p className="titulo-ferramenta-btn">Odontograma</p>
               <div onClick={()=>{

                array  = montagemstate
                array.push({ type: "odontologico", component: <CardOdontologico getData={getdata}  tempId={id}/> , tempId : id , title : ''  , required : false, idsessao:idComponentAtual })   
               
                setMontagem(array)
                setAtt(!att)



               }} className="envolve-btn-add-ferramentas">
                   <IconButton className="btn-add-ferramenta">
                       <AddIcon/>
                   </IconButton>
               </div>
            </div>
  );
}