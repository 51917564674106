import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularLoad from '../Load';
import DialogContentText from '@material-ui/core/DialogContentText';

const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      }
    },
  });

export default function ModalExcluir(props) { 
    const [load, setLoad] = useState(props.load);
    const [openModalDelete, setOpenModalDelete] = React.useState(props.open);
    const [titulo, setTitulo] = React.useState(props.titulo);
    const [descricao, setDescricao] = React.useState(props.descricao);

    useEffect(() => {
        setLoad(props.load);
        setOpenModalDelete(props.open);
        setTitulo(props.titulo);
        setDescricao(props.descricao);
    }, [props])


    function handleExclusao(value){
        props.handleModal(value)
    }

    return(
            <div>
                <ThemeProvider theme={theme}>
                <Dialog
                    open={openModalDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{titulo}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {descricao}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>handleExclusao(false)} color="default">
                        Cancelar
                    </Button>
                    <Button onClick={()=>handleExclusao(true)} variant="contained" className="color-primary" color="primary" autoFocus>
                        {!load ? 'Sim, excluir' : <CircularLoad/>}
                    </Button>
                    </DialogActions>
                </Dialog>
                </ThemeProvider>
            </div>
     );
}