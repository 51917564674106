import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import '../css/formLogin.css';
import CircularLoad from '../../../Components/Load';
import { validateEmail, validatePasswordLogin } from './validate';
import { useHistory } from 'react-router-dom';
import conexao from '../../../Services/api'
import conexao2 from '../../../Services/api2'
import Alert from '@material-ui/lab/Alert';
import { StateGlobal } from '../../../ContextAPI/context'
import { ContactsOutlined } from '@material-ui/icons';
 
//const {notification,setNotification} = StateGlobal() 

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& .MuiTextField-root': {
      marginBottom: '1rem',
      width: '100%',
    },
  },
}));

export default function FormLogin() {
  const classes = useStyles();
  const history = useHistory();

  const [email, setEmail] = useState()
  const [load, setLoad] = useState(false)
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  
  const [logado, setLogado] = useState(false)
  const { notification, setNotification, setNotatt, notfyatt } = StateGlobal()

  //import Alert from '@material-ui/lab/Alert';
  const alerts = [<Alert variant="filled" severity="success">Logando</Alert>,
  <Alert variant="filled" severity="error">Email ou senha inválidos</Alert>,
  <Alert variant="filled" severity="error">Acesse o sistema Clinic Plus</Alert>]


  function enviaLogin(event) {
    localStorage.removeItem('token')
    localStorage.removeItem('userId')
    event.preventDefault();
    if (validateEmail(email) && validatePasswordLogin(values.password)) {
      setLoad(true)

      conexao.post('/login', { "email": email, "password": values.password }).then((res) => {
        if(res.data.data.type === 'adm'){
        setLoad(false)
        localStorage.setItem('token', res.data.data.token)
        localStorage.setItem('userId', res.data.data.id)
   

        setLogado(0)
  
        setTimeout(() => {
          history.push('/home');
        }, 1000);
        }else{
          setLoad(false)
          setLogado(2)
        }

      }).catch((error) => {
        setLoad(false)
        setLogado(1)
      })

      // conexao2.post('/login', { "email": email, "password": values.password }).then((res) => {
      //   if(res.data.data.type === 'adm'){
      //   setLoad(false)
      //   localStorage.setItem('token2', res.data.data.token)

      //   setLogado(0)
  
      //   setTimeout(() => {
      //     history.push('/home');
      //   }, 1000);
      //   }else{
      //     setLoad(false)
      //     setLogado(2)
      //   }

      // }).catch((error) => {
      //   setLoad(false)
      //   setLogado(1)
      // })
    }
  }




  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };




  return (
    <>
      <div className='Login-alert'>
        {logado === 0 ? alerts[logado] : ''}
        {logado === 1 ? alerts[logado] : ''}
        {logado === 2 ? alerts[logado] : ''}
      </div>
      <form onSubmit={(e) => enviaLogin(e)} className={classes.root} validate autoComplete="off">


        <div>
          <TextField
            className="Login-input-color"
            id="email"
            label="E-mail"
            onChange={(e) => { setEmail(e.target.value) }}
            error={email && !validateEmail(email)}
            helperText={email && !validateEmail(email) ? 'Email inválido' : ''}
            type="email"
            placeholder="Digite o seu e-mail"
            variant="outlined"
            color="primary"
          />
          <FormControl
            error={values.password && !validatePasswordLogin(values.password)}
            className="Login-senha-input" variant="outlined">
            <InputLabel htmlFor="senha">Senha</InputLabel>
            <OutlinedInput
              className="Login-input-color"
              id="senha"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange('password')}
              aria-describedby="component-error-text"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={45}
            />
            <FormHelperText id="component-error-text">{values.password && !validatePasswordLogin(values.password) ? 'Senha inválida' : ''}</FormHelperText>
          </FormControl>
        </div>



        <div className="Login-div-btn-entrar">
          <Button
            className="Login-button color-primary"
            type="submit"
            variant="contained"
            color="primary">
            {!load ? 'Entrar' : <CircularLoad />}
          </Button>
        </div>
      </form>
    </>
  );
}