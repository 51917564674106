import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux'
import store from './Store/store'
import ContextAPI from './ContextAPI/context'

ReactDOM.render(
  <React.StrictMode>
    <ContextAPI>
      <Provider store={store}>
        <App />
      </Provider>
    </ContextAPI>
  </React.StrictMode>,
  document.getElementById('root')
);
