import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TotalResultado from './components/total';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 0,
    paddingTop: 100,
    backgroundColor:'#F3F3F3',
    minHeight: '100vh',
    height: '100%'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: '100vh',
    backgroundColor: '#F3F3F3'
  },
  espacamento:{
      padding: 20,
      paddingTop: 0
  },
  container:{
    margin: 15,
    marginTop: 0
  }
}));

export default function RelatorioFinanceiro(props) {
  const classes = useStyles();

  const [csvObject, setCsvObject] = useState([]);

  const getTotalResultsData = (cities, occupations) => {    
    let newParams = cities.concat(occupations)

    let mapedNewParams = newParams.map((param) => {
      return {
        'Total de usuários por cidade:': 
          Object.keys(param)[0] === 'city'
          ? `${param.city || 'Cidade não cadastrada'} - ${param.quantity}` 
          : null,
        'Total de usuários por profissão:':
          Object.keys(param)[0] === 'name'
          ? `${param.name} - ${param.quantity}`
          : null,
      } 
    })

    mapedNewParams.forEach((el) => {
      Object.keys(el).forEach(key => {
        if (el[key] === null) {
          delete el[key];
        }
      });
    })

    console.log('mapedNewParams:', mapedNewParams)

    setCsvObject(mapedNewParams)
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
      <Grid container className={classes.espacamento}>
        <Grid item xs={12}>
        
        <div className="cabecalho-personalizado">
          <h1>Relatório de Usuários</h1>
          <div>
            <CSVLink
              data={csvObject}
              filename={'usuarios-segmento.csv'}
              separator={";"}
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant="contained"
                color="primary"
                className="color-primary"
              >
                <GetAppIcon /> Exportar para Excel  
              </Button>
            </CSVLink>
          </div>
        </div>  
        <div className="tabela-personalizada">
          <TotalResultado getTotalResultsData={getTotalResultsData}/>
        </div>
        </Grid>
      </Grid>
      </div>
   
    </div>
  );
}