import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, useHistory } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import Logo from '../../Assets/Images/logoBranco.svg';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import GroupIcon from '@material-ui/icons/Group';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ModalSair from '../Modais/ModalSair';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListIcon from '@material-ui/icons/List';
import TodayIcon from '@material-ui/icons/Today';
import ModalNotificacao from '../Modais/ModalNotificacao';
import AmpStoriesIcon from '@material-ui/icons/AmpStories';
import ModalSuporte from '../Modais/ModalSuporte';
import './css/menuTopo.css';
import conexao from '../../Services/api'
import { StateGlobal } from '../../ContextAPI/context';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
      color: "#fff",
    }),
    backgroundColor: "#27b8d2",
    color: "#fff",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  subIcon: {
    marginLeft: "1rem",
  },
  iconVoltar: {
    color: "#fff",
  },
  text: {
    color: "#fff",
    textDecoration: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,

  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#27b8d2",
    color: "#fff",
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  envolveNot: {
    height: '14vh',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      background: "#f9f9f9",
    },
  },
  envolveNotActive: {
    backgroundColor: '#27b8d217',
  },
  colorLateralNot: {
    width: '5px',
    height: '100%',
  },
  circuloNot: {
    backgroundColor: '#27b8d2',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
  },
  envolveNotEData: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: '1rem',
    height: '100%',
    justifyContent: 'flex-end',
  },
  envolveCorTitle: {
    width: '75%',
    display: 'flex',
    height: '100%',
  },
  envolveTitle: {
    marginLeft: '1rem',
    width: '100%',
    '& h5': {
      fontSize: '1em',
      marginBottom: 0,
    },
    '& p': {
      fontSize: '.9em',
      marginTop: '0.7rem',
      marginBottom: '1rem',
    },
  },
  dataNot: {
    '& p': {
      marginBottom: '1rem',
      fontSize: '.8em',
      color: '#8e8e8e',
      marginTop: '1rem',
    },
  },

  titleModal: {
    fontSize: '1.4em',
    margin: '0',
    marginTop: '.5rem',
    marginBottom: '.5rem',
    color: '#4a4a4a',
  },

  divTitleModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid #ececec',
  },

  colorAgendada: {
    backgroundColor: '#e13567'
  },
  colorSistema: {
    backgroundColor: '#b1b1b1'
  },
  colorCancelada: {
    backgroundColor: '#3f51b5'
  },
  envolveNotificacoes: {
    overflow: 'auto',
    height: '50vh',
  }
}));

export default function PersistentDrawerRight() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openGestao, setOpenGestao] = React.useState(false);
  const [openGestaoTopo, setOpenGestaoTopo] = React.useState(false);
  const [openOutros, setOpenOutros] = React.useState(false);
  const [openOutrosTopo, setOpenOutrosTopo] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElNot, setAnchorElNot] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [openModalNotificacao, setOpenModalNotificacao] = React.useState(false);
  const [openModalSuporte, setOpenModalSuporte] = React.useState(false);
  const [openModalSair, setOpenModalSair] = React.useState(false);
  const [load, setLoad] = useState(false)
  const [titulo, setTitulo] = React.useState('');
  const [descricao, setDescricao] = React.useState('');
  const History = useHistory();
  const [fotoPerfil, setFotoPerfil] = React.useState('');
  const { attAgenda, setAttAgenda } = StateGlobal()
  const [nome, setNome] = React.useState('');

  useEffect(() => {

    getDadosCadastro()
  }, [])



  async function getDadosCadastro() {
    const config = {
      headers: { Authorization: localStorage.getItem('token') }
    };

    await conexao.get(`/doctor`, config)
      .then((resp) => {
        var userId = '';
        if (localStorage.getItem('userId')) {
          userId = localStorage.getItem('userId');
          var cadastro = resp.data.data;
          for (let i = 0; i < cadastro.length; i++) {
            if (userId == cadastro[i].user_id) {
              setFotoPerfil(cadastro[i].avatar);
              setNome(cadastro[i].user.name);
            }
          }
        } else {
          History.push('/');
        }
      }).catch(function (error) {
        if (error.response.status==401) {
          History.push('/')
        }
      });

  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const mudaGestao = () => {
    setOpenGestao(!openGestao);
    handleDrawerOpen();
  };

  const mudaGestaoTopo = () => {
    setOpenGestaoTopo(!openGestaoTopo);
  };

  const mudaOutros = () => {
    setOpenOutros(!openOutros);
    handleDrawerOpen();
  };

  const mudaOutrosTopo = () => {
    setOpenOutrosTopo(!openOutrosTopo);
  };


  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuNotOpen = (event) => {
    setAnchorElNot(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMeuPerfil = () => {
    setAnchorEl(null);
    History.push('/perfil')
  };

  const handleMenuNotClose = () => {
    setAnchorElNot(null);
  };


  const handleModalSair = (value) => {
    if (value) {
      sair()
    } else {
      handleCloseSair()
    }
  };


  function modalSuporte() {
    setOpenModalSuporte(true);
  }

  function modalSair() {
    setOpenModalSair(true);
  }

  const handleClose = () => {
    setOpenModalNotificacao(false);
  }

  const handleCloseSuporte = () => {
    setOpenModalSuporte(false);
  }
  const handleCloseSair = () => {
    setOpenModalSair(false);
  }

  const sair = () => {
    setLoad(true);
    conexao.post('/logout').then((res) => {
      setLoad(false)
      localStorage.clear();
      History.push('/');
    }).catch((error) => {
      setLoad(false)
      localStorage.clear();
      History.push('/');
    })
  }

  const menuId = 'primary-search-account-menu';
  const menuIdNot = 'primary-search-not-menu';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMeuPerfil}>Perfis Administrativos</MenuItem>
      <MenuItem onClick={modalSair}>Sair</MenuItem>
    </Menu>
  );

  // const renderMenuNot = (
  //   <Menu
  //     PaperProps={{
  //       style: {
  //         height: '60vh',
  //         width: '62.9ch',
  //       },
  //     }}
  //     anchorEl={anchorElNot}
  //     anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  //     id={menuIdNot}
  //     keepMounted
  //     transformOrigin={{ vertical: 'top', horizontal: 'right' }}
  //     open={isMenuOpenNot}
  //     onClose={handleMenuNotClose}
  //   >
  //     <div className={classes.divTitleModal}>
  //       <h1 className={classes.titleModal}>Notificações</h1>
  //     </div>
  //     <div className={classes.envolveNotificacoes}>
  //       {not ?
  //         not.map((item) => {
  //           let color = 'colorCancelada'
  //           if (item.message) {
  //             if (item.message.type.id === 4) {
  //               color = 'colorSistema'
  //             }
  //             if (item.message.type.id === 3) {
  //               color = 'colorCancelada'
  //             }
  //             if (item.message.type.id === 2) {
  //               color = 'colorAgendada'
  //             }
  //             if (item.message.type.id === 1) {
  //               color = 'colorAgendada'
  //             }
  //           }
  //           return (
  //             <div
  //               className={item.read === 0 ? clsx(classes.envolveNot, classes.envolveNotActive) : classes.envolveNot}
  //               onClick={() =>
  //                 item.message ?
  //                   exibeMensagem(item.message.title, item.message.message, item.id) : ''}>
  //               <div className={classes.envolveCorTitle}>
  //                 <div className={clsx(classes.colorLateralNot, classes[color])}></div>
  //                 <div className={classes.envolveTitle}>
  //                   <h5>{item.message ? item.message.type.name : ''} </h5>
  //                   <p>{item.message ? item.message.title : ''} </p>
  //                 </div>
  //               </div>
  //               <div className={classes.envolveNotEData}>
  //                 <div ></div>
  //                 {item.read === 0 ?
  //                   <div className={classes.circuloNot}></div> : ""}
  //                 <div className={classes.dataNot}><p>{item ? item.send_at : ''}</p></div>
  //               </div>
  //             </div>)
  //         })
  //         : ''}
  //       {/* <div 
  //         className={clsx(classes.envolveNot, classes.envolveNotActive)}
  //         onClick={()=>exibeMensagem(2)}>
  //         <div className={classes.envolveCorTitle}>
  //         <div className={clsx(classes.colorLateralNot,classes.colorSistema)}></div>
  //           <div className={classes.envolveTitle}>
  //             <h5>Atualização de sistema</h5>
  //             <p>Lorem Ipsum Dolor</p>
  //           </div>
  //         </div>
  //         <div className={classes.envolveNotEData}> 
  //           <div className={classes.circuloNot}></div>
  //           <div className={classes.dataNot}><p>13/12/2020 às 12:00</p></div>
  //         </div>
  //       </div> */}
  //       {/* <div 
  //         className={clsx(classes.envolveNot)}
  //         onClick={()=>exibeMensagem(3)}>
  //         <div className={classes.envolveCorTitle}>
  //           <div className={clsx(classes.colorLateralNot,classes.colorAgendada)}></div>
  //           <div className={classes.envolveTitle}>
  //             <h5>Consulta agendada</h5>
  //             <p>Lorem Ipsum Dolor</p>
  //           </div>
  //         </div>
  //         <div className={classes.envolveNotEData}> 
  //           <div ></div>
  //           <div className={classes.dataNot}><p>13/12/2020 às 12:00</p></div>
  //         </div>
  //       </div> */}
  //     </div>
  //   </Menu>
  // );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <div className="menuTopo-envolve-logo-botoes">
            <div className="MenuTopo-logo-login">
              <Link to="/home">
                <img src={Logo} alt="logo" />
              </Link>
            </div>
            <div className="menuTopo-menu">
              <List className={"menuTopo-menu-lateral"}>
                <ListItem button className={classes.text}>
                  <Link to="/clinicas" className={classes.textPrinc}><ListItemText primary="Clínicas" /></Link>
                </ListItem>
                <ListItem button className={classes.text}>
                  <Link to="/usuarios" className={classes.textPrinc}><ListItemText primary="Usuários" /></Link>
                </ListItem>
                  <ListItem onclick={setAttAgenda(true)} button className={classes.text}>
                    <Link to="/listagem-modelos" className={classes.textPrinc}><ListItemText primary="Prontuários" /></Link>
                  </ListItem>
                  <ListItem button className={classes.text}>
                    <Link to="/notificacoes" className={classes.textPrinc}><ListItemText primary="Notificações" /></Link>
                  </ListItem>
                <div>
                  <ListItem button onClick={mudaOutrosTopo} className={classes.text}>
                    <ListItemText primary="Relatórios" />
                    {openOutrosTopo ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse className="menuTopo-collapse" in={openOutrosTopo} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem button className={classes.text, open ? classes.submenu : classes.submenuColapse}>
                        <Link to="/relatorio-usuarios" className={classes.text}><ListItemText primary="Usuários" /></Link>
                      </ListItem>
                      <ListItem button className={classes.text, open ? classes.submenu : classes.submenuColapse}>
                        <Link to="/relatorio-financeiro" className={classes.text}><ListItemText primary="Financeiro" /></Link>
                      </ListItem>
                      <ListItem button className={classes.text, open ? classes.submenu : classes.submenuColapse}>
                        <Link to="/relatorio-agendamento" className={classes.text}><ListItemText primary="Agendamentos" /></Link>
                      </ListItem>
                    </List>
                  </Collapse>
                </div>
                <ListItem button className={classes.text}>
                    <Link to="/cupons" className={classes.textPrinc}><ListItemText primary="Cupons" /></Link>
                  </ListItem>
              </List>
            </div>
            <div className={classes.sectionDesktop}>
              {/* <IconButton aria-label="show 4 new mails" color="inherit">
                  <Badge badgeContent={4} color="secondary">
                    <MailIcon />
                  </Badge>
                </IconButton> */}

              {/* <IconButton
                aria-label="show 10 new notifications"
                aria-controls={menuIdNot}
                onClick={handleProfileMenuNotOpen}
                color="inherit">
                <Badge badgeContent={badge} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton> */}
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                className="menuTopo-img-perfil"
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Avatar alt={nome} src={fotoPerfil} />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                className={clsx(open && classes.hide, "menuTopo-icon-hamburguer")}
              >
                <MenuIcon />
              </IconButton>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
      {/* {renderMenuNot} */}
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton className={classes.iconVoltar} onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List className={[classes.lista, "menuScroll"]}>
          <ListItem button className={classes.text}>
            <ListItemIcon>
              <Link to="/usuarios">
                {open ?
                  <GroupIcon className={classes.iconVoltar} /> :
                  <Tooltip title="Usuários" placement="right">
                    <GroupIcon className={classes.iconVoltar} />
                  </Tooltip>
                }
              </Link>
            </ListItemIcon>
            <Link to="/usuarios" className={classes.textPrinc}><ListItemText primary="Usuários" /></Link>
          </ListItem>

          <ListItem onclick={setAttAgenda(true)} button className={classes.text}>
            <ListItemIcon>
              <Link to="/listagem-modelos">
                {open ?
                  <AssignmentIcon className={classes.iconVoltar} /> :
                  <Tooltip title="Prontuários" placement="right">
                    <AssignmentIcon className={classes.iconVoltar} />
                  </Tooltip>
                }
              </Link>
            </ListItemIcon>
            <Link to="/listagem-modelos" className={classes.textPrinc}><ListItemText primary="Prontuários" /></Link>
          </ListItem>
          <Collapse in={openGestao} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.text, open ? classes.submenu : classes.submenuColapse}>
                <ListItemIcon className={classes.submenuIcon}>
                  <Link to="/">
                    {open ?
                      <MoreVertIcon className={clsx(classes.iconVoltar, classes.subIcon)} /> :

                      <Tooltip title="Itens" placement="right">
                        <MoreVertIcon className={clsx(classes.iconVoltar, classes.subIcon)} />
                      </Tooltip>
                    }
                  </Link>
                </ListItemIcon>
                <Link to="/" className={classes.text}><ListItemText primary="Itens" /></Link>
              </ListItem>
            </List>
          </Collapse>

          <ListItem button className={classes.text}>
            <ListItemIcon>
              <Link to="/notificacoes">
                {open ?
                  <NotificationsIcon className={classes.iconVoltar} /> :
                  <Tooltip title="Notificações" placement="right">
                    <NotificationsIcon className={classes.iconVoltar} />
                  </Tooltip>
                }
              </Link>
            </ListItemIcon>
            <Link to="/notificacoes" className={classes.textPrinc}><ListItemText primary="Notificações" /></Link>
          </ListItem>

          <ListItem button onClick={mudaOutros} className={classes.text}>
            <ListItemIcon onClick={handleDrawerOpen}>
              {open ?
                <ListIcon className={classes.iconVoltar} /> :

                <Tooltip title="Relatórios" placement="right">
                  <ListIcon className={classes.iconVoltar} />
                </Tooltip>
              }
            </ListItemIcon>
            <ListItemText primary="Relatórios" />
            {openOutros ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openOutros} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.text, open ? classes.submenu : classes.submenuColapse}>
                <ListItemIcon className={classes.submenuIcon}>
                  <Link to="/relatorio-usuarios">
                    {open ?
                      <AssignmentIndIcon className={clsx(classes.iconVoltar, classes.subIcon)} /> :

                      <Tooltip title="Usuários" placement="right">
                        <AssignmentIndIcon className={clsx(classes.iconVoltar, classes.subIcon)} />
                      </Tooltip>
                    }
                  </Link>
                </ListItemIcon>
                <Link to="/relatorio-usuarios" className={classes.text}><ListItemText primary="Usuários" /></Link>
              </ListItem>
              <ListItem button className={classes.text, open ? classes.submenu : classes.submenuColapse}>
                <ListItemIcon className={classes.submenuIcon}>
                  <Link to="/relatorio-financeiro">
                    {open ?
                      <AssessmentIcon className={clsx(classes.iconVoltar, classes.subIcon)} /> :

                      <Tooltip title="Financeiro" placement="right">
                        <AssessmentIcon className={clsx(classes.iconVoltar, classes.subIcon)} />
                      </Tooltip>
                    }
                  </Link>
                </ListItemIcon>
                <Link to="/relatorio-financeiro" className={classes.text}><ListItemText primary="Financeiro" /></Link>
              </ListItem>
              <ListItem button className={classes.text, open ? classes.submenu : classes.submenuColapse}>
                <ListItemIcon className={classes.submenuIcon}>
                  <Link to="/relatorio-agendamento">
                    {open ?
                      <TodayIcon className={clsx(classes.iconVoltar, classes.subIcon)} /> :

                      <Tooltip title="Agendamento" placement="right">
                        <TodayIcon className={clsx(classes.iconVoltar, classes.subIcon)} />
                      </Tooltip>
                    }
                  </Link>
                </ListItemIcon>
                <Link to="/relatorio-agendamento" className={classes.text}><ListItemText primary="Agendamento" /></Link>
              </ListItem>
            </List>
          </Collapse>
          <ListItem button className={classes.text}>
            <ListItemIcon>
              <Link to="/cupons">
                {open ?
                  <AmpStoriesIcon className={classes.iconVoltar} /> :
                  <Tooltip title="Cupons" placement="right">
                    <AmpStoriesIcon className={classes.iconVoltar} />
                  </Tooltip>
                }
              </Link>
            </ListItemIcon>
            <Link to="/cupons" className={classes.textPrinc}><ListItemText primary="Cupons" /></Link>
          </ListItem>
          <ListItem onClick={modalSair} button className={classes.text}>
            <ListItemIcon>
              <Link onClick={modalSair}>
                {open ?
                  <ExitToAppIcon className={classes.iconVoltar} /> :
                  <Tooltip title="Sair" placement="right">
                    <ExitToAppIcon className={classes.iconVoltar} />
                  </Tooltip>
                }
              </Link>
            </ListItemIcon>
            <Link onClick={modalSair} className={classes.textPrinc}><ListItemText primary="Sair" /></Link>
          </ListItem>
        </List>
      </Drawer>
      <ModalSair
        handleModal={handleModalSair}
        open={openModalSair}
        load={load}
      />
    </div>
  );
}