import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularLoad from '../../../Components/Load';
import DialogContentText from '@material-ui/core/DialogContentText';
import { validateEmail } from '../Form/validate';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import conexao from '../../../Services/api'
import clsx from 'clsx';
import Alert from '@material-ui/lab/Alert';

const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      }
    },
  });

  const useStyles = makeStyles((theme) => ({
    textField: {
        width: '100%',
        marginTop: '1rem',
        marginBottom: '1rem',
    },
  }));

export default function ModalEsqueciSenha(props) { 
    const classes = useStyles();
    const [load, setLoad] = useState(props.load);
    const [openModalEsqueci, setOpenModalEsqueci] = React.useState(props.open);
    const [email, setEmail] = useState('')
    const [typeAlert, setTypeAlert] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        setLoad(props.load);
        setOpenModalEsqueci(props.open);
    }, [props])


    function handleEsqueciSenha(value){
        if(!value){
            props.handleModal(value)
        }else{
            enviaReset();
        }
    }

    
  function enviaReset() {
    setLoad(true)
    if (email && validateEmail(email)) {
      conexao.post('/reset', { "email": email}).then((res) => {
        setTypeAlert('success');
        setMessage('Um e-mail com as instruções e o link para criar uma nova senha foi enviado para você!');
        setLoad(false)
        setTimeout(() => {
          setTypeAlert('');
          setMessage('');
          props.handleModal(true)
        }, 3000);

      }).catch((error) => {
        setLoad(false)
        setTypeAlert('error');
        setMessage('E-mail não encontrado. Por favor verifique o campo e-mail e tente novamente!');
        setTimeout(() => {
          setTypeAlert('');
          setMessage('');
        }, 3000);
      })
    }else{
        setLoad(false)
        setTypeAlert('error');
        setMessage('E-mail inválido!');
        setTimeout(() => {
          setTypeAlert('');
          setMessage('');
        }, 3000);
    }
  }


    return(
            <div>
                <ThemeProvider theme={theme}>
                    <Dialog
                        open={openModalEsqueci}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Esqueci minha senha</DialogTitle>
                        <DialogContent>
                        {message ?
                            <div className="Modal-agendamento-alert">
                            <Alert variant="filled" severity={typeAlert}>
                                {message}
                            </Alert>
                            </div>
                        : ''}
                        <DialogContentText id="alert-dialog-description">
                            Indentifique-se para receber um e-mail com as instruções e o link para criar uma nova senha.
                        </DialogContentText>
                        <form>
                        <TextField
                            className={clsx(classes.textField,"Login-input-color")}
                            id="email"
                            label="E-mail"
                            onChange={(e) => { setEmail(e.target.value) }}
                            error={email && !validateEmail(email)}
                            helperText={email && !validateEmail(email) ? 'Email inválido' : ''}
                            type="email"
                            placeholder="Digite o seu e-mail"
                            variant="outlined"
                            color="primary"
                        />
                        </form>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={()=>handleEsqueciSenha(false)} color="default">
                            Cancelar
                        </Button>
                        <Button onClick={()=>handleEsqueciSenha(true)} variant="contained" className="color-primary" color="primary" autoFocus>
                            {!load ? 'Enviar' : <CircularLoad className={classes.colorLoad}/>}
                        </Button>
                        </DialogActions>
                    </Dialog>
                </ThemeProvider>
            </div>
     );
}