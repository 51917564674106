import React, { useState, useEffect } from 'react';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CardSecaoSalvas from './CardSecoesCriadas'
import '../css/NovoModelo.css';
import { StateGlobal } from '../../../../ContextAPI/context'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import conexao from './../../../../Services/api'
import Alert from '@material-ui/lab/Alert';
import CardTextoCurto from './../SecaoInput/CardTextoCurto'
import CardTextoLongo from './../SecaoInput/Components/CardTextoLongo'
import CardSelecao from './../SecaoInput/Components/CardSelecao'
import CardData from './../SecaoInput/Components/CardData'
import CardEmail from './../SecaoInput/Components/CardEmail'
import CardNumerico from './../SecaoInput/Components/CardNumerico'
import CardTelefone from './../SecaoInput/Components/CardTelefone'
import CardLabel from './../SecaoInput/Components/CardLabel'
import CardCheck from './../SecaoInput/Components/CardCheck'
import CardImagem from './../SecaoInput/Components/CardImagem'
import CardCurvadeCrescimento from './../SecaoInput/Components/CardCurvadeCrescimento'


//           'odontologico'
//   'osteopatiapediatrica'
// 'desenvolvimentomotor'

import CardOdontologico from './../SecaoInput/Components/CardOdontologico'
import CardOsteopatia from './../SecaoInput/Components/CardOsteopatiaPediatrica'
import CardDesenvolvimentoMotor from './../SecaoInput/Components/CardDesenvolvimentoMotor'
import CardEscaladeDor from './../SecaoInput/Components/CardEscalaDeDor'


import { IoMdTennisball } from 'react-icons/io';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '93%',
    },
  },
}));

const theme = createMuiTheme({
  palette: {
    secondary: {
      light: '#E7366A',
      main: '#E7366A',
      dark: '#D93364',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
});

export default function InfoProntuarioLateralEsquerdo() {
  const classes = useStyles();
  const history = useHistory();
  const { sessaostate,
    setSessaostate,
    montagemstate,
    setMontagem,
    components,
    setComponents,
    setAtt,
    idComponentAtual,
    setIdcomponentAtual,
    att,
    setTituloSessaoAtual,
    idEditProntuario,
    setIdEditProntuario, 
    viewProntuario,
    } = StateGlobal();

  const [tituloformulario, setTituloFormulario] = useState()
  const [open, setOpen] = useState({ open: false, alert: 0 });
  const [errorOptions, setErrorOptions] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const alerterror = [<Alert style={{ width: '94%' }} variant="filled" severity="error">Dados inválidos</Alert>,
  <Alert variant="filled" severity="success">Salvo com sucesso</Alert>]

  useEffect(() => {
    if (open.open && open.alert === 0) {
      setDisabled(false)
    }
  }, [open])

  useEffect(() => {
    const id = Math.floor(Math.random() * 256)

    const arredit = []

    if (idEditProntuario) {

      setTituloFormulario(idEditProntuario.data.title)

      idEditProntuario.data.steps.map((item, index) => {
        const idsessao = Math.floor(Math.random() * 256)
        if (index === 0) {
          setIdcomponentAtual(idsessao)
        }

        const components = []
        item.questions.map((item) => {
        
          const idcomponent = Math.floor(Math.random() * 9999)

          if (item.type === 'textolongo') {
            components.push({ type: item.type, component: <CardTextoLongo tempId={idcomponent} />, tempId: idcomponent, title: item.description, required: item.role, idsessao: idsessao })
          }
          if (item.type === 'textocurto') {
            components.push({ type: item.type, component: <CardTextoCurto tempId={idcomponent} />, tempId: idcomponent, title: item.description, required: item.role, idsessao: idsessao })
          }
          if (item.type === 'data') {
            components.push({ type: item.type, component: <CardData tempId={idcomponent} />, tempId: idcomponent, title: item.description, required: item.role, idsessao: idsessao })
          }
          if (item.type === 'numerico') {
            components.push({ type: item.type, component: <CardNumerico tempId={idcomponent} />, tempId: idcomponent, title: item.description, required: item.role, idsessao: idsessao })
          }
          if (item.type === 'telefone') {
            components.push({ type: item.type, component: <CardTelefone tempId={idcomponent} />, tempId: idcomponent, title: item.description, required: item.role, idsessao: idsessao })
          }
          if (item.type === 'email') {
            components.push({ type: item.type, component: <CardEmail tempId={idcomponent} />, tempId: idcomponent, title: item.description, required: item.role, idsessao: idsessao })
          }
          if (item.type === 'image') {
            components.push({ type: item.type, component: <CardImagem tempId={idcomponent} />, tempId: idcomponent, title: item.description, required: item.role, idsessao: idsessao })
          }
          if (item.type === 'label') {
            components.push({ type: item.type, component: <CardLabel tempId={idcomponent} />, tempId: idcomponent, title: item.description, required: item.role, idsessao: idsessao })
          }
          if (item.type === 'odontologico') {//NOVO ODONTOLOGICO EDICAO
            components.push({ type: item.type, component: <CardOdontologico tempId={idcomponent} />, tempId: idcomponent, title: item.description, required: item.role, idsessao: idsessao })
          }
          if (item.type === 'osteopatiapediatrica') {//NOVO ODONTOLOGICO EDICAO
            components.push({ type: item.type, component: <CardOsteopatia tempId={idcomponent} />, tempId: idcomponent, title: item.description, required: item.role, idsessao: idsessao })
          }
          if (item.type === 'curvadecrescimento') {//NOVO ODONTOLOGICO EDICAO
            components.push({ type: item.type, component: <CardCurvadeCrescimento tempId={idcomponent} />, tempId: idcomponent, title: item.description, required: item.role, idsessao: idsessao })
          }
          if (item.type === 'desenvolvimentomotor') {//NOVO ODONTOLOGICO EDICAO
            components.push({ type: item.type, component: <CardDesenvolvimentoMotor tempId={idcomponent} />, tempId: idcomponent, title: item.description, required: item.role, idsessao: idsessao })
          }


          if (
            item.type === 'escaladedor' ||
            item.type === 'cranianovistainferior' ||
            item.type === 'musculoesqueletico' ||
            item.type === 'visceral' ||
            item.type === 'cranianovistaanterior' ||
            item.type === 'cranianovistaposterior' ||
            item.type === 'cranianovistalateraldireita'
          ) {
            components.push({ 
              type: item.type,
              component: <CardEscaladeDor tempId={idcomponent} />,
              tempId: idcomponent,
              title: item.description,
              required: item.role,
              idsessao: idsessao
            })
          }

          if (item.type === 'selecao' || item.type === 'check') {
           
            const arrop = []
            if (item.options) {
              item.options.map((item) => {
                arrop.push({ text: item.text, value: "" })
              })
            }
            
            if (item.type === 'selecao') {
              components.push({ type: item.type, component: <CardSelecao tempId={idcomponent} opcoes={arrop} title={item.description} />, tempId: idcomponent, title: item.description, required:  item.role, idsessao: idsessao, opcoes: arrop })
            }
            if (item.type === 'check') {
         
              components.push({ type: item.type, component: <CardCheck tempId={idcomponent} opcoes={arrop} title={item.description} />, tempId: idcomponent, title: item.description, required:  item.role, idsessao: idsessao, opcoes: arrop })
              
            }
          }
          //  }
        })

        arredit.push({ 
          componentsessao: <CardSecaoSalvas id={idsessao} titulosessao={item.description} />,
          id: idsessao, 
          montagem: components, 
          titulosessao: item.description 
        })
      })

      
      setMontagem(arredit[0].montagem)
      setSessaostate(arredit)
      
      console.log('ARREDIT:', arredit)
      
      
    } else {
      
      setIdcomponentAtual(id)
      setSessaostate([{ componentsessao: <CardSecaoSalvas id={id} />, id: id, montagem: [], titulosessao: 'Título da seção prontuário' }])
    }
  }, [])
  

  function sessao(result) {//controle array 3
    if (!result.destination) return;

    const items = Array.from(sessaostate); // importante
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSessaostate(items);
  }

  return (
    <div className="NovoModelo-InfoProntuario">
      <ThemeProvider theme={theme}>
        <div className="NovoModelo-InfoProntuario-FormPront">
          <p className="NovoModelo-InfoProntuario-nomePront">Nome do prontuário</p>

          <form className={classes.root} noValidate autoComplete="off">
            {open.open ? alerterror[open.alert] : ''}
            {/* CRIAÇÃO DE JSON DE CRICACAO DE COMPONENTES */}
            <TextField required onChange={(e) => { setTituloFormulario(e.target.value) }} InputLabelProps={{ shrink: true }} value={tituloformulario} className="background-input" id="nome" label="Nome do prontuário" variant="outlined" disabled={viewProntuario}/>


            {!viewProntuario ? 
            <Button variant="contained" className="color-primary" onClick={() => {
              setDisabled(true)
              const steps = []
              let formcreate = {}

              

              sessaostate.map((item, index) => {
                
                const questions = []

                const types = ['textocurto', 'textolongo', 'data', 'numerico', 'telefone', 'email', 'label', 'image']

                item.montagem.map((item, index) => {

                  types.map((itemtype) => {
                    if (item.type === itemtype) {
                      questions.push({
                        question: item.title,
                        type: item.type,
                        order: index,
                        role: item.required
                      })
                    }
                  })

                  if (item.type === 'selecao' || item.type === 'check') {
  
                    questions.push({
                      question: item.title,
                      type: item.type,
                      order: index,
                      role: item.required,
                      options: item.options,

                    })

                  }

                  if (
                    item.type === 'odontologico' ||
                    item.type === 'osteopatiapediatrica' ||
                    item.type === 'desenvolvimentomotor' ||
                    item.type === 'curvadecrescimento'
                  ) {
                    questions.push({
                      question: item.type,
                      type: item.type,
                      order: index,
                      role: item.required,
                      options: item.options,
                    })
                  }

                  if (
                    item.type === 'escaladedor' ||
                    item.type === 'cranianovistainferior' ||
                    item.type === 'musculoesqueletico' ||
                    item.type === 'visceral' ||
                    item.type === 'cranianovistaanterior' ||
                    item.type === 'cranianovistaposterior' ||
                    item.type === 'cranianovistalateraldireita'
                  ) {
                    questions.push({
                      question: item.type,
                      type: item.type,
                      order: index,
                      role: item.required,
                      options: item.options,
                    })
                  }
                })

                steps.push({
                  description: item.titulosessao,
                  order: index,
                  questions: questions
                })
              })

              formcreate = {
                user_id: localStorage.getItem('userId'),
                title: tituloformulario,
                description: 'form de teste',
                type: 'default',
                publish: 0,
                steps: steps
              }

              console.log('STEPS:', steps)

              if (idEditProntuario) {
                
                conexao.put('/form/update/' + idEditProntuario.id, formcreate).then((res) => {
                  setOpen({ open: true, alert: 1 })
                
                  setTimeout(() => {
                    history.push('/listagem-modelos')
                  }, 2000);
                }).catch((error) => {
                  setOpen({ open: true, alert: 0 })
                })

                setTimeout(() => {
                  setOpen({ open: false, alert: 0 })

                }, 2000);

              } else {  
       
                conexao.post('/form/create', formcreate).then((res) => {
                  setOpen({ open: true, alert: 1 })
                  setTimeout(() => {
                    history.push('/listagem-modelos')
                  }, 2000);
                }).catch((error) => {
                  setOpen({ open: true, alert: 0 })
                })

                setTimeout(() => {
                  setOpen({ open: false, alert: 0 })
                }, 2000);
              }


            }} className={disabled ? 'Color-btn-disabled' : 'Color-btn-salvarModelo'} color="primary" disabled={disabled}>
              {idEditProntuario ? 'SALVAR' : 'CONCLUIR'}
            </Button>
            : ''}
            <Button variant="contained" onClick={() => history.push('/listagem-modelos')} className="Color-btn-cancelarModelo">
            Cancelar
            </Button>
          </form>
        </div>
        <div className="NovoModelo-InfoProntuario-Secao">
          <div className="NovoModelo-Secao-btn-add">
            <p className="NovoModelo-InfoProntuario-nomePront">Seções do Prontuário</p>
            {!viewProntuario ? 
            <Button onClick={() => {
              const id = Math.floor(Math.random() * 9999)

              var arr = sessaostate
              setMontagem([])
              arr.push({ componentsessao: <CardSecaoSalvas id={id} />, id: id, montagem: [], titulosessao: 'Título da seção prontuário' })


              setIdcomponentAtual(id)

              // setSessaostate(arr)
              // setAtt(!att)
              setTituloSessaoAtual('Título da seção prontuário')//importante deixa sessao reativa titulo

              sessaostate.map((item, index) => {
                if (item.id === idComponentAtual) {

                  sessaostate[index].montagem = montagemstate
                  // sessaostate[index].titulosessao = titulo
                  }

                const newarray = [...sessaostate]
                setSessaostate(newarray)

                //   setTituloSessaoAtual('Título da seção prontuário')
                // var arrteste = sessaostate
                // setSessaostate(arrteste)
                // setAtt(!att)
                setOpen({ open: true, msg: 'informações gravadas com sucesso!', type: 'success' })

                setTimeout(() => {

                  setOpen({ open: false, msg: '', type: '' })

                }, 5000);
              })


            }
            } className="btn-add-secao" href="#text-buttons" color="primary">
              <AddIcon /> Adicionar seção
          </Button>
          : ''}
          </div>
          <div className="envolve-secoes">
            <DragDropContext onDragEnd={sessao}>
              <Droppable droppableId="montagemstate">
                {(provided) => (
                  <ul {...provided.droppableProps} ref={provided.innerRef}>
                    {sessaostate && sessaostate.map(({ id, type, componentsessao, idcerto }, index) => {


                      return (
                        <Draggable key={id.toString()} draggableId={id.toString()} index={index}>
                          {(provided) => (

                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>

                              <div >

                                {componentsessao}
                            

                              </div>

                            </div>

                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>







          </div>
        </div>
      </ThemeProvider>
    </div>
  );
}

