import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularLoad from '../../../Components/Load';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import validator from 'validator'
import conexao from '../../../Services/api'
import '../css/PerfilAdm.css';

const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      }
    },
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        marginBottom: theme.spacing(1),
        width: '100%',
      },
      width: '100%'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginBottom: '1rem',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '96%',
      },
  }));
  

export default function ModalExcluir(props) { 
    const [load, setLoad] = useState(props.load);
    const classes = useStyles();
    const [openModalAdd, setOpenModalAdd] = useState(props.open);
    const [id, setId] = useState(props.id);
    const [message, setMessage] = React.useState('');
    const [typeAlert, setTypeAlert] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [values, setValues] = React.useState({
      password: '',
      showPassword: false,
    });
    const [validaName, setValidaName] = useState(false);
    const [validaEmail, setValidaEmail] = useState(false);
    const [validaSenha, setValidaSenha] = useState(false);
    const validateEmail = (e) => {
      var email = e.target.value
      setEmail(email)
      if (validator.isEmail(email)) {
        setValidaEmail(false)
      } else {
        setValidaEmail(true)
      }
    }

    useEffect(() => {
        setName('');
        setEmail('')
        setLoad(props.load);
        setOpenModalAdd(props.open);
        setId(props.id);
        listaUsuarios(props.id)
    }, [props])


    function handleAdd(value){
      if(value){
        cadastroAdm();
      }else{
        setName('');
        setEmail('')
        setValues({ ...values, password: '' });
        props.handleModal(value)
      }
    }

    const handleChange = (prop) => (event) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  
    const handleClickShowPassword = () => {
      setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    function listaUsuarios(idUser){
      setName('');
      setEmail('')
      const data ={
        "search": "",
      }
        conexao.post(`/adm/user/get`, data)
        .then((response)=>{
          var dados = response.data.data.data;
          setName('');
          setEmail('')
          for(var i = 0; dados.length > i; i++){
            if(idUser==dados[i].id){
              setName(dados[i].name);
              setEmail(dados[i].email)
            }
          }
  
        }).catch((error)=>{
        })
    
    }

    async function cadastroAdm(){
      setLoad(true);
      if(values.password.length>5){
        setValidaSenha(false)
        if(name!=='' && email !=='' && values.password !==''){
          setValidaName(false)
          setValidaEmail(false)
          setValidaSenha(false)
          if(!id){
            await conexao.post('/adm/user/create',
            {
                "name": name,
                "email": email,
                "password": values.password,
            })
            .then((response) => {
              setLoad(false);
              setMessage('Cadastro realizado com sucesso!');
              setTypeAlert('success');
              setTimeout(() => {
                setMessage('');
                setTypeAlert('');
                setName('');
                setEmail('');
                setValues({
                  password: '',
                  showPassword: false,
                });
                props.handleModal(true)
                window.location.reload()
              }, 2000);
            }).catch((error)=>{
              setLoad(false);
              setMessage('Esse e-mail já está cadastrado. Por favor confira os dados e tente novamente!');
              setTypeAlert('error');
              setTimeout(() => {
                setMessage('');
                setTypeAlert('');
              }, 2000);
            })
          }else{
            await conexao.put(`/adm/user/update/${id}`,
            {
                "name": name,
                "email": email,
                "password": values.password,
            })
            .then((response) => {
              setLoad(false);
              setMessage('Administrador atualizado com sucesso!');
              setTypeAlert('success');
              setTimeout(() => {
                setMessage('');
                setTypeAlert('');
                setName('');
                setEmail('');
                setValues({
                  password: '',
                  showPassword: false,
                });
                props.handleModal(true)
                window.location.reload()
              }, 2000);
            }).catch((error)=>{
              setLoad(false);
              setMessage('Erro ao atualizar o administrador. Por favor, tente novamente!');
              setTypeAlert('error');
              setTimeout(() => {
                setMessage('');
                setTypeAlert('');
              }, 2000);
            })
          }
      }else{
        setLoad(false);
        if(name===''){
          setValidaName(true)
        }if(email===''){
          setValidaEmail(true)
        }if(values.password===''){
          setValidaSenha(true)
        }
        return true;
      }
    }else{
      setLoad(false);
      setValidaSenha(true)
    }
    }

    return(
            <div>
                <ThemeProvider theme={theme}>
                <Dialog
                    className="modal-Add-licenca"
                    open={openModalAdd}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{!id?'Cadastrar novo administrador':'Editar administrador'}</DialogTitle>
                    {message !== ''?
                      <Alert className="messagensFixed" variant="filled" severity={typeAlert}>
                        {message}
                      </Alert>
                    :''}
                    <form className={classes.root} noValidate autoComplete="off">
                      {id && !name ? <div className="circular-edit-form"><CircularLoad/></div> : ''}
                        <DialogContent>
                          <TextField
                              id="nome"
                              label="Nome"
                              type="text"
                              variant="outlined"
                              value={name}
                              disabled={id && !name ? true : false}
                              onChange={(e)=>setName(e.target.value)}
                              className={classes.textField}
                              error={validaName ?true:false}
                              helperText={validaName ? 'Campo obrigatório' : ''}
                          />
                          <TextField
                            id="email"
                            label="E-mail"
                            type="email"
                            variant="outlined"
                            value={email}
                            disabled={id && !email ? true : false}
                            onChange={(e)=>validateEmail(e)}
                            className={classes.textField}
                            error={validaEmail ?true:false}
                            helperText={validaEmail ? 'E-mail inválido' : ''}
                          />
                          <FormControl error={validaSenha ?true:false} htmlFor="component-error" className={clsx(classes.margin, classes.textField)} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Senha de acesso</InputLabel>
                                <OutlinedInput
                                  id="outlined-adornment-password"
                                  type={values.showPassword ? 'text' : 'password'}
                                  value={values.password}
                                  onChange={handleChange('password')}
                                  aria-describedby="component-error-text"
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  labelWidth={125}
                                />
                                <FormHelperText id="component-error-text">{validaSenha ? 'A senha deve ter pelo menos 6 caracteres' : ''}</FormHelperText>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={()=>handleAdd(false)} color="default">
                            Cancelar
                        </Button>
                        <Button  onClick={()=>handleAdd(true)} variant="contained" className="color-primary" color="primary" autoFocus>
                            {!load ? 'Salvar' : <CircularLoad/>}
                        </Button>
                        </DialogActions>
                    </form>
                </Dialog>
                </ThemeProvider>
            </div>
     );
}