import axios from 'axios';
 
const api = axios.create({

    baseURL:process.env.REACT_APP_NEW_API_URL,

    headers: {
        'Authorization': localStorage.getItem('token')  , //TESTE PARA O ERRO 500 DA API
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
});

export default api ;
