import React, {useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tabela from './components/tabelaAdm';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Modal from './components/ModalNovoAdm';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 0,
    paddingTop: 100,
    backgroundColor:'#F3F3F3',
    minHeight: '100vh',
    height: '100%'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: '100vh',
    backgroundColor: '#F3F3F3'
  },
  espacamento:{
      padding: 20,
      paddingTop: 0
  },
  container:{
    margin: 15,
    marginTop: 0
  }
}));

export default function Usuarios(props) {
  const classes = useStyles();
  const [load, setLoad] = useState(false)
  const [openmodalAdd, setOpenmodalAdd] = useState(false);

  function close() {
    setOpenmodalAdd(false)
  }

  const handleModalAdd = (value) => {
      close();
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
      <Grid container className={classes.espacamento}>
        <Grid item xs={12}>
        
        <div className="cabecalho-personalizado">
          <h1>Administradores</h1>
          <div>
            <Button variant="contained" color="primary" className="color-primary" onClick={() => setOpenmodalAdd(true)}>
              <AddIcon /> Novo administrador 
            </Button>
          </div>
        </div>  
        <div className="tabela-personalizada">
          <Tabela/>
        </div>
        </Grid>
      </Grid>
      </div>
      <Modal open={openmodalAdd} load={load} handleModal={handleModalAdd} close={close} />
    </div>
  );
}