import axios from 'axios'

export function teste(e) {
    if (e.length > 2) {
        return 'erro esse aqui e maior que 2'
    }
    if (e === "") {
        return 'esse campo precisa ser preenchido '
    }
    else {
        return false
    }
}

export function teste2(e) {
    if (e.length > 5) {
        return true
    } else {
        return false
    }
}


export function telefonefixo(e) {
    let value = e
    value = (value.replace(/\D/g, ""));
    value = value.replace(/(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3");
    return value

}


export function dataehora(e){
    if(e !== null){

    var arr = e.split(" ")

    
    const data = arr[0].split("-")
    
    const dataformatada = data[2] + "/" + data[1] + "/" + data[0]
 
    const hora = arr[1].split(":")

    const horaformatada = hora[0] + ":" + hora[1]

    return dataformatada+ ' às ' +horaformatada
    }
}



export function telefones(e) {
    let value = e
   
    value = (value.replace(/\D/g, ""));
    value = value
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
        .replace(/(-\d{4})\d+?$/, '$1')
    return value
}


export function dataRegex(e) {
    let value = e
    value = (value.replace(/\D/g, ""));
    value = value
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d{4})/, '$1/$2')
        .replace(/(\d{4})\d+?$/, '$1')
    return value
}


export function data(e) {
    //let value = e
    var arr = e.split(" ")
    const data = arr[0].split("-")
    const dataformatada = data[2] + "/" + data[1] + "/" + data[0]


    // value = value.replace(/\D/g, "");
    // value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    // value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

    return dataformatada

}


export function hora(e) {
    //let value = e
    var arr = e.split(" ")
    const data = arr[1].split(":")

    const horaformatada = data[0] + ":" + data[1]
    // value = value.replace(/\D/g, "");
    // value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    // value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

    return horaformatada
}

export function currency(e) {

    let value = parseFloat(e)
    value = value.toFixed(2)
    value = value.toString()
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
    return value
}


export function currencyinput(e) {

    //     let value = parseFloat(e)

    //     value = value.toFixed(2)
    //    value = value.toString()

    //    value = value.replace(/\D/g, "");
    //    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    //    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

    //    return  value

    let value = e.toString()


    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

    return value
}

export function celular(e) {
    let value = e
    value = (value.replace(/\D/g, ""));
    value = value.replace(/(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
    return value
}

export function cpf(e) {
    let value = e
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
    // value = value
    //         .replace(/(\d{3})(\d)/, "$1.$2")
    //         .replace(/(\d{3})(\d)/, "$1.$2")
    //         .replace(/(\d{3})(\d{2})\d+?/, "$1-$2")


    return value
}

export function rg(e) {
    let value = e
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/, "$1.$2.$3-$4")
    // .replace(/(-\d{1})\d+?$/, '$1')

    return value
}

export function cnpj(e) {
    let value = e
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
    return value
}


export function ValidaCep(cep) {
    var exp = /\d{2}\.\d{3}\-\d{3}/
    if (!exp.test(cep.value)) {
        return false
    } else {
        return true
    }
}


export function apicep(cep) {
    axios.get('https://viacep.com.br/ws/' + cep.replace(/\D/g, "") + '/json/').then((res) => {

    }).catch((error) => {
  
    })
}


export function validaCpfCnpj(val) {// validar cpf ou cpnj verdadeiro

    var valor = val.replace(/\D/g, "")
    if (valor.length < 14) {
        var cpf = valor.trim();


        cpf = cpf.replace(/\D/g, "")




        var v1 = 0;
        var v2 = 0;
        var aux = false;

        for (var i = 1; cpf.length > i; i++) {
            if (cpf[i - 1] != cpf[i]) {
                aux = true;
            }
        }

        if (aux == false) {
            return false;
        }

        for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
            v1 += cpf[i] * p;
        }

        v1 = ((v1 * 10) % 11);

        if (v1 == 10) {
            v1 = 0;
        }

        if (v1 != cpf[9]) {
            return false;
        }

        for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
            v2 += cpf[i] * p;
        }

        v2 = ((v2 * 10) % 11);

        if (v2 == 10) {
            v2 = 0;
        }

        if (v2 != cpf[10]) {
            return false;
        } else {
            return true;
        }
    } else if (val.length > 14) {
        var cnpj = val.trim();

        cnpj = cnpj.replace(/\./g, '');
        cnpj = cnpj.replace('-', '');
        cnpj = cnpj.replace('/', '');
        cnpj = cnpj.split('');

        var v1 = 0;
        var v2 = 0;
        var aux = false;

        for (var i = 1; cnpj.length > i; i++) {
            if (cnpj[i - 1] != cnpj[i]) {
                aux = true;
            }
        }

        if (aux == false) {
            return false;
        }

        for (var i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
            if (p1 >= 2) {
                v1 += cnpj[i] * p1;
            } else {
                v1 += cnpj[i] * p2;
            }
        }

        v1 = (v1 % 11);

        if (v1 < 2) {
            v1 = 0;
        } else {
            v1 = (11 - v1);
        }

        if (v1 != cnpj[12]) {
            return false;
        }

        for (var i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) {
            if (p1 >= 2) {
                v2 += cnpj[i] * p1;
            } else {
                v2 += cnpj[i] * p2;
            }
        }

        v2 = (v2 % 11);

        if (v2 < 2) {
            v2 = 0;
        } else {
            v2 = (11 - v2);
        }

        if (v2 != cnpj[13]) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
}

export function validateEmail(email) {
    if(email === ''){
        return true

    }
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(String(email).toLowerCase());

}


export function validatePasswordLogin(password) {

    
    if(password.length < 6 ){
    return false
    }else{
        return true
    }

 
} 