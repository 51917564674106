import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tabela from './components/tabelaCupons';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Modal from './components/ModalCriarCupom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 0,
    paddingTop: 100,
    backgroundColor:'#F3F3F3',
    minHeight: '100vh',
    height: '100%'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: '100vh',
    backgroundColor: '#F3F3F3'
  },
  espacamento:{
      padding: 20,
      paddingTop: 0
  },
  container:{
    margin: 15,
    marginTop: 0
  }
}));

export default function Cupom(props) {
  const classes = useStyles();
  const [openModal, setOpenModal] = React.useState(false);
  const [load, setLoad] = useState(false);

  const handleModal = (value) => {
    if (value) {
      handleClose()
    } else {
      handleClose()
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  }

  function criarCupom(id) {
    setOpenModal(true);
  }


  return (
    <div className={classes.root}>
      <div className={classes.container}>
      <Grid container className={classes.espacamento}>
        <Grid item xs={12}>
        
        <div className="cabecalho-personalizado">
          <h1>Cupons</h1>
          <div>
            <Button onClick={criarCupom} variant="contained" color="primary" className="color-primary">
              <AddIcon /> Novo cupom
            </Button>
          </div>
        </div>  
        <div className="tabela-personalizada">
          <Tabela/>
        </div>
        </Grid>
      </Grid>
      </div>
        <Modal
            handleModal={handleModal}
            open={openModal}
            load={load}
        />
    </div>
  );
}