import React, {useState, useEffect} from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import { useHistory } from "react-router-dom";
import CardMeusModelos from './CardModelosPublicados';
import CardModelosProntos from './CardModelosNaoPublicados';
import { StateGlobal } from '../../../ContextAPI/context'

import './css/Listagem.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100%"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },

}));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
}, ptBR);

function TabPanel(props) {
  const { children, value, index, ...other } = props;



  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function tabPesquisa(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export default function ListagemModelosSalvos() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const history = useHistory();


  const {
    idEditProntuario,
    setIdEditProntuario,
    setTituloSessaoAtual,
    setMontagem,
    setSessaostate,  
  } = StateGlobal();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const novoModelo = () => {
    setIdEditProntuario(false)
    setMontagem([])
    setSessaostate([])
    setTituloSessaoAtual('Título da seção prontuário')

    history.push('/novo-modelo');
  }

  return (
    <div className="ListagemModelo-container">
      <Grid container>
      <Grid item xs={12}>
        <Paper className={clsx(classes.paper, classes.colorPaper)}>
          <div className="ListgemModelo-prontuarios">
          <ThemeProvider theme={theme}>
            <div className="ListagemModelo-prontuario-name-button">
              <h1>Prontuários</h1>
              <Button onClick={novoModelo} variant="contained" className="Color-btn-addModelo">
                <AddIcon/> Novo modelo
              </Button>
            </div>
          <div className="padding-lateral app-bar-modelos-pront">
                <AppBar position="static">
                    <Tabs  indicatorColor="primary" textColor="primary" value={value} onChange={handleChange} aria-label="tab-pesquisa">
                    <Tab label="Modelos publicados" {...tabPesquisa(0)} />
                    <Tab label="Modelos não publicados" {...tabPesquisa(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel className="retirar-padding-lateral" value={value} index={0}>
                    <CardMeusModelos/>
                </TabPanel>
                <TabPanel className="retirar-padding-lateral" value={value} index={1}>
                  <CardModelosProntos/>
                </TabPanel>
            </div>
          </ThemeProvider>
          </div>
        </Paper>
      </Grid>
    </Grid>
  </div>
  );
}