import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import api from '../../../../Services/api'
import {useHistory} from 'react-router-dom';
import CircularLoad from '../../../../Components/Load';
import PropTypes from 'prop-types';
import '../../css/Relatorios.css';
import TabelaCidades from './tabelas/cidades';
import TabelaProfissoes from './tabelas/profissoes';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  envolveResultados:{
    display: 'flex',
  },
  envolveResultado:{
    '& > p': {
        fontSize: '1.5em',
        fontWeight: '400',
        margin: '0',
        marginBottom: '.5rem',
        color: '#8f8f8f',
    },
    '& > h2': {
        fontSize: '2.5em',
        fontWeight: '600',
        margin: '0',
        color: '#27b8d2'
    },
    margin: '1rem',
    display: 'flex',
    flexDirection: 'column',
    width: '34%',
    height: '450px',
},
tabela:{
  border: '1px solid #e2e2e2',
  borderRadius: '8px',
  margin: '1rem',
  padding: '1rem',
  boxShadow: '0px 0px 6px -3px #a0a0a0',
  marginRight: '3rem',
  maxWidth:'100%',
  minWidth:'100%',
  height: '400px'
}
}));

export default function RelatorioUsuariosResultado({getTotalResultsData}) {
  const classes = useStyles();
  const [search, setSearch] = React.useState('');
  const [dataInicial, setDataInicial] = React.useState('');
  const [dataFinal, setDataFinal] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [typeAlert, setTypeAlert] = useState('');
  const [total, setTotal] = useState('');
  const [cidades, setCidades] = useState('');
  const [profissoes, setProfissoes] = useState('');
  const [semResultado, setSemResultado] = React.useState(false);
  const [load, setLoad] = useState(true)
  const [limparStatus, setLimparStatus] = useState(false)
  const history = useHistory();
  const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      }
    },
  }, ptBR);


 function listaRelatorioUsuarios(newPage){
    setSemResultado(false)
    setLoad(true)

    const data ={
        "started_at": dataInicial,
        "finished_at": dataFinal
    }
      api.post(`/adm/report/doctor`, data)
      .then((response)=>{
        setLoad(false)
        setMessage('')
        setTypeAlert('')
            if(response.data.data){
              getTotalResultsData( 
                response.data.data.city_doctors,
                response.data.data.speciality_doctors 
              )
              setTotal(response.data.data.total_doctors)
              setCidades(response.data.data.city_doctors)
              setProfissoes(response.data.data.speciality_doctors)
            }else{
                setSemResultado(true)
                setMessage('Nenhum resultado encontrado')
                setTypeAlert('error')
            }
      }).catch((error)=>{
        setLoad(false)
        setSemResultado(true)
        setMessage('Nenhum resultado encontrado')
        setTypeAlert('error')
      })
  }

  useEffect(() => {
    listaRelatorioUsuarios();
  }, []);


  useEffect(() => {
    if(search===''){
      listaRelatorioUsuarios()
    }
  
  },[search]);
  

  useEffect(() => {
    listaRelatorioUsuarios()
  }, [limparStatus]);

  const capturaDataFinal = (prop) => (event) => {
    setDataFinal(event.target.value);
};

const capturaDataInicial = (prop) => (event) => {
  setDataInicial(event.target.value);
};


function limpar(){
  setSearch('')
  setDataInicial('');
  setDataFinal('');
  setLimparStatus(!limparStatus)
  setLoad(true)
}

const busca = (event) => {
  if (event.key === 'Enter') {
    setLoad(true)
    listaRelatorioUsuarios()
  } if (event._reactName === 'onClick') {
    setLoad(true)
    listaRelatorioUsuarios()
  }
  return true
}


  return (
    <div className={classes.root}>
         <ThemeProvider theme={theme}>
      <div className="tableuser">
      <div className="historico-user-envolve-busca">
        <div className="historico-user-input-data user-historico">
        <TextField className="date"
          id="outlined-basic" 
          value={dataInicial}
          onChange={capturaDataInicial()}
          label="Data inicial"  
          color="primary"  
          variant="outlined" 
          type="date" 
          maxLength = "10"
          inputProps ={{maxLength:10}}
          InputLabelProps={{
            shrink: true,
          }}
         />
        <TextField className="date"
          id="outlined-basic" 
          value={dataFinal}
          label="Data final"
          onChange={capturaDataFinal()}  
          color="primary"  
          variant="outlined"
          type="date" 
          maxLength = "10"
          inputProps ={{maxLength:10}}
          InputLabelProps={{
            shrink: true,
          }}
         />
          <div className="envolve-btn-filtro-user">
              <Button variant="contained" color="primary" className="color-primary" onClick={listaRelatorioUsuarios}>
                Filtrar data
              </Button>
              <Button variant="contained" color="secondary" className="color-secondary" onClick={()=>{limpar()}}>
                Limpar filtro
              </Button>
          </div>
        </div>
        </div>
        <div className="historico-user-div-msg">
            {message !== ''?
              <Alert className="messagensFixed" variant="filled" severity={typeAlert}>
                 {message}
              </Alert>
                  :''}
        </div>
        {!semResultado ?
        !load ? 
        <div className={classes.envolveResultados}>
            <div className={classes.envolveResultado}>
                <p>Total de usuários:</p>
                <h2>{total}</h2>
            </div>
            {cidades.length>0?
            <div className={classes.envolveResultado}>
              <p>Total de usuários por cidade:</p>
              <div className={classes.tabela}>
                  <TabelaCidades cidades={cidades} load={load} />
              </div>
            </div>
            :''}
            {profissoes.length>0?
              <div className={classes.envolveResultado}>
                <p>Total de usuários por profissão:</p>
                <div className={classes.tabela}>
                  <TabelaProfissoes profissoes={profissoes} load={load}/>
                </div>
              </div>
            :''}
       </div>
      :  <div className="div-load-lista-modelos"><CircularLoad/></div>
      :''}
      </div>
</ThemeProvider>
    </div>
  );
}