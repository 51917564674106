import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularLoad from '../../../Components/Load';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import '../css/Notificacao.css';
import { campovazio } from '../../../Components/ReduxTeste/ValidateForm'
import { StateGlobal } from '../../../ContextAPI/context';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Alert from '@material-ui/lab/Alert';
import Select from '@material-ui/core/Select';
import conexao from '../../../Services/api'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100%',
    },
    width: '98%'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '96%',
    marginBottom: '1rem'
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '96%',
    marginBottom: '1rem'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));




export default function ModalExcluir(props) {
  const [load, setLoad] = useState(props.load);
  const classes = useStyles();
  const [typeAlert, setTypeAlert] = useState('');
  const [message, setMessage] = useState('');
  const [openModalEditar, setOpenModalEditar] = useState(props.open);
  const [respSelect, setRespSelect] = useState('');
  const { attTableNotificacoes, setAttTableNotificacoes } = StateGlobal();
  const [type, setType] = React.useState({
    type: '',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setType({
      ...type,
      [name]: event.target.value,
    });
  };


  const state = {

    title: { name: 'title', funcao: campovazio, value: '', error: false },
    menssagem: { name: 'menssagem', funcao: campovazio, value: '', error: false },

  }

  const [form, setForm] = useState(state)

  function pegadados(e) {
    const { name, value } = e
    const obj = form[name]
    const er = form[name].funcao(value)
    let att = { ...obj, error: er, value: value }
    setForm({ ...form, [name]: att })
  }

  useEffect(() => {
    getTypeNotificacao()
    setForm(state)
    setLoad(props.load);
    setOpenModalEditar(props.open);
  
  }, [props])


  function handleEditar(value) {
    props.handleModal(value)
  }


  function Enviar() {
    var pass = true

    const arr = Object.values(form)
    arr.map((item) => {

      if (item.value === '') {
        form[item.name].error = item.funcao(item.value)
        pass = false
      }
      setForm({ ...form })
    })


    if (pass) {
      setLoad(true)
      const data = {
        title: form.title.value,
        message: form.menssagem.value,
        notification_type_id: type.type,      
      }

      conexao.post('/adm/notification/message/create', data).then((res) => {
        setLoad(false)
        setTypeAlert('success');
        setMessage('Modelo de notificação criado com sucesso');
        setTimeout(() => {
          setTypeAlert('');
          setAttTableNotificacoes(!attTableNotificacoes)
          handleEditar(false)
          setMessage('');
        }, 1500);
      }).catch((error) => {
        setLoad(false)
        setTypeAlert('error');
        setMessage('Erro ao criar um novo modelo de notificação. Tente novamente!');
        setTimeout(() => {
          setTypeAlert('');
          setMessage('');
        }, 1500);
      })



    }
  }

  async function getTypeNotificacao() {
    await conexao.get('/adm/notification/type/get')
      .then((resp) => {
        setRespSelect(resp.data.data)
          setType({
            ...type,
            type: 1,
          });
      }).catch((error) => {

      })

  }

    return (
      <div>
        <ThemeProvider theme={theme}>
          <Dialog
            className="modal-editar-licenca"
            open={openModalEditar}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Novo modelo de notificação</DialogTitle>
            {message !== ''?
              <Alert className="messagensFixed" variant="filled" severity={typeAlert}>
                 {message}
              </Alert>
            :''}
            <form className={classes.root} noValidate autoComplete="off">
              <DialogContent>
                <TextField
                  id="cupom"
                  label="Título"
                  type="text"
                  variant="outlined"
                  className={classes.textField}
                  name='title'
                  value={form.title.value}
                  onChange={(e) => { pegadados(e.target) }}
                  error={form.title.error ? true : false}
                  helperText={form.title.error ? form.title.error : ''}
                />


                <TextField
                  id="limite"
                  label="Mensagem"
                  type="text"
                  variant="outlined"
                  rows={4}
                  className={classes.textField}
                  multiline
                  name='menssagem'
                  value={form.menssagem.value}
                  onChange={(e) => { pegadados(e.target) }}
                  error={form.menssagem.error ? true : false}
                  helperText={form.menssagem.error ? form.menssagem.error : ''}
                />
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-age-native-simple">Tipo de notificação</InputLabel>
                    <Select
                      native
                      value={type.type}
                      onChange={handleChange}
                      label="Tipo de notificação"
                      inputProps={{
                        name: 'type',
                        id: 'outlined-age-native-simple', 
                      }}
                    >
                      {respSelect !=='' ?
                        respSelect.map((tipo) => {
                          return <option value={tipo.id}>{tipo.name}</option>
                        })
                      :''}
                    </Select>
                  </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleEditar(false)} color="default">
                  Cancelar
                        </Button>
                <Button onClick={() => Enviar()} variant="contained" className="color-primary" color="primary" autoFocus>
                  {!load ? 'Salvar' : <CircularLoad />}
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </ThemeProvider>
      </div>
    );
  }