import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tabela from './tabelaHistorico';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 0,
    paddingTop: 100,
    backgroundColor:'#F3F3F3',
    minHeight: '100vh',
    height: '100%'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: '100vh',
    backgroundColor: '#F3F3F3'
  },
  espacamento:{
      padding: 20,
      paddingTop: 0
  },
  container:{
    margin: 15,
    marginTop: 0
  },
  iconHr:{
    marginRight: '.3rem'
  }
}));

export default function HistoricoNotificacao() {
  const classes = useStyles();
  const [load, setLoad] = useState(false);
  const history = useHistory();


  return (
    <div className={classes.root}>
      <div className={classes.container}>
      <Grid container className={classes.espacamento}>
        <Grid item xs={12}>
        
        <div className="cabecalho-personalizado">
          <h1>Notificação enviadas</h1>
        </div>  
        <div className="tabela-personalizada">
          <Tabela/>
        </div>
        </Grid>
      </Grid>
      </div>
    </div>
  );
}