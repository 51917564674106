

export default function (state = {} , action) {
    switch (action.type) {
        case 'TESTEREDUX':
            return  {...state, ...action.payload }     

        default:
            return state
    }
}

