import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import Button from '@material-ui/core/Button';
import { TextField, MenuItem, Select, InputLabel, FormControl, ListItemText } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import CircularLoad from '../../../Components/Load';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import conexao from '../../../../Services/api'
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import './categoria.css'
import { currency, currencyinput, data as datreplace } from '../../../../Components/ReduxTeste/Validate'
// import ModalExcluir from '../../../Components/Modais/ModalExclusao';
import Modal from '../Modal'
import { campovazio, teste2  } from '../../../../Components/ReduxTeste/ValidateForm'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: '1rem',
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',

    background: '#F9F9F9'
  },
  input: {
    width: '100%',
    marginTop: '1rem',
    display: 'flex',
    backgroundColor: 'white',
  },
  pConfig: {
    color: '#8F8F8F',
    minWidth: '30%',
    maxWidth: '30%',
    textAlign: 'left',
    wordWrap: 'break-word'
  },
  envolveConfig: {
    borderBottom: '1px solid #8F8F8F',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '1rem',
    paddingLeft: '1rem',
  },
  envolveConfigs: {
    height: '35vh',
    overflow: 'auto',
    backgroundColor: 'white',
    paddingRight: '1rem',
    paddingLeft: '1rem',
    marginTop: '1rem',
    marginBottom: '1.5rem'
  },
  modalColor: {
    backgroundColor: '#f9f9f9',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  selectTipoAtendimento: {
    width: '100%',
    backgroundColor: 'white'
  },
  label: {
    background: 'white',
    paddingRight: '.4rem'
  },
}));

const theme = createMuiTheme({
  palette: {
    secondary: {
      light: '#E7366A',
      main: '#E7366A',
      dark: '#D93364',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },


  },
}, ptBR);


export default function ModalCategoria(props) {
  const classes = useStyles();
  const History = useHistory();

  const initialvalues = {
    procedimento: '',
    valor: '',
  }


  const initialState = {
    description: props.edit.open ? props.edit.data.description : '',
    due_date: props.edit.open ? props.edit.data.due_date : '',
    value: props.edit.open ? props.edit.data.value.toString() : '',
    payment_type: props.edit.open ? props.edit.data.payment_type : '',
    status: props.edit.open ? props.edit.data.status : '',
    user_id: localStorage.getItem('userId'),
    finance_category_id: props.edit.open ? props.edit.data.finance_category.name : '',
  }




  const [open, setOpen] = useState(props.openrecebimento)
  const [values, setValues] = useState(initialvalues)
  const [valor, setValor] = useState()
  const [procedimento, setProcedimento] = useState();
  const [typeAlert, setTypeAlert] = useState();
  const [message, setMessage] = useState();
  const [itens, setItens] = useState();
  const [procId, setProcId] = useState();
  const [editar, setEditar] = useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [load, setLoad] = useState(false)
  const [idEdit, setIdedit] = React.useState();
  const [idExcluir, setIdexcluir] = React.useState();
  const [openmodalexcluir, setOpenmodalexcluir] = useState(false);
  const [openalert, setOpenalert] = useState({ open: false })
  const [idcategoria, setIdcategoria] = useState(0)


  const validatevalues= [
    { name: 'due_date', funcao: campovazio, error: false , value:'' },
    { name: 'finance_category_id', funcao: campovazio, error: false , value:''},
    { name: 'description', funcao: campovazio, error: false , value:''},
    { name: 'value', funcao: campovazio, error: false , value:''},
    { name: 'payment_type', funcao: campovazio, error: false , value:''},
    { name: 'status', funcao: campovazio, error: false , value:''}]
  
  
  const [validatesx, setvalidatesx] = useState([
    { name: 'due_date', funcao: campovazio, error: false , value:'' },
    { name: 'finance_category_id', funcao: campovazio, error: false , value:''},
    { name: 'description', funcao: campovazio, error: false , value:''},
    { name: 'value', funcao: campovazio, error: false , value:''},
    { name: 'payment_type', funcao: campovazio, error: false , value:''},
    { name: 'status', funcao: campovazio, error: false , value:''}]);


useEffect(()=>{

  setvalidatesx(validatevalues)

},[props.openrecebimento])

function onchangeform(e) {
  const { name, value } = e.target
  validatesx.map((item) => {
    if (item.name === name) {
      item.error = item.funcao(value)
      item.value = value
    }
  })
  const arr = validatesx.slice()
  setvalidatesx(arr)
}



  const [data, setData] = useState(initialState)

  function datamask(e) {
    var arr = e.split(" ")
    return arr[0]

  }

  function onchange(e) {
    const { name, value } = e.target;
    if (name === 'value') {
      var replace = value.replace(/\D/g, "").replace(/(\d)(\d{2})$/, "$1.$2");
      setData({ ...data, [name]: replace });
    }
    else {
      setData({ ...data, [name]: value });
    }

  }


  useEffect(() => {


    setOpen(props.openrecebimento)
    setEditar(false)
    setValor('')
    setProcedimento('')
    setData(initialState)
    listaProcedimentos()




  }, [props])

  // useEffect(() => {
  //   listaProcedimentos()
  // }, [])



  const closeModal = () => {
    setOpen(false)
    props.close()
  }

  function close() {
    setOpenmodalexcluir(false)
  }

  function listaProcedimentos() {
    conexao.get('/finance/category/get/' + localStorage.getItem('userId')).then((res) => {
      setItens(res.data.data)

    }).catch((error) => {
    })
  }

  async function salvaProcedimentos() {
    var oldnamefinace = data.finance_category_id
  

if(props.edit.open){
  data.finance_category_id = idcategoria
  data.due_date= datamask(data.due_date)
  conexao.put('/finance/update/'+props.edit.data.id, data).then((res)=>{

    Alertshow('Editado com sucesso !', 'success')
    data.finance_category_id = oldnamefinace

  }).catch((error)=>{
    data.finance_category_id = oldnamefinace
    setData({...data})

    Alertshow('Campos inválidos!', 'error') 
  })

}else{
  data.finance_category_id = idcategoria

  validatesx.map((item)=>{
    if(item.value === ''){
      item.error = true
    }
      conexao.post('finance/create', data).then((res) => {
      data.finance_category_id = oldnamefinace
      Alertshow('Salvo com sucesso !', 'success')
      setData(initialState)
      setvalidatesx(validatevalues)

    

    }).catch((error) => {
      data.finance_category_id = oldnamefinace
      setData({...data})
      Alertshow('Campos inválidos!', 'error')
    })

    
    const arr = validatesx.slice()
    setvalidatesx(arr)
  })
  }
  }

  function editarprocedimento() {
    conexao.put('finance/category/update/' + idEdit, { name: procedimento, type: valor, user_id: localStorage.getItem('userId') }).then((res) => {
      setEditar(false)
      setValor('')
      setProcedimento('')
      listaProcedimentos()
      Alertshow('Editado com sucesso !', 'success')

    }).catch((error) => {
      Alertshow('erro ao editar !', 'success')
    })

  }

  function Alertshow(msg, type) {
    setOpenalert({ open: true, msg: msg, type: type })
    setTimeout(() => {
      setOpenalert({ open: false })
    }, 2000);
  }




  function modaldeletaProcedimentos(id) {
    setOpenmodalexcluir(true)
    setIdexcluir(id)
  }

  function deletaProcedimento() {
    conexao.delete('/finance/category/' + idExcluir).then((res) => {
      listaProcedimentos()

    }).catch((error) => {

    })

  }



  function selecionaProcedimento(id) {
    setEditar(true);
    itens.map((item) => {
      if (item.id === id) {
        setValor(item.type)
        setProcedimento(item.name)
      }
      setIdedit(id)
    })
  }

  useEffect(() => {
    listaProcedimentos();
  }, [])



  return (
    <div className="Modal-agendamento">
      <ThemeProvider theme={theme}>
        <Dialog open={open} aria-labelledby="Modal-title-agendamento">
          <form id="" className={classes.modalColor} autoComplete="off">
            <DialogTitle id="Modal-title-agendamento">
              <div className="title-modal-close">
                Novo lançamento
                
            <CloseIcon onClick={closeModal} />
              </div>
           
            </DialogTitle>
            <DialogContent>
              {openalert.open ?
                <div className="Modal-agendamento-alert">
                  <Alert variant="filled" severity={openalert.type}>
                    {openalert.msg}
                  </Alert>
                </div>
                : ''}
              <div>

           
                <TextField
                  id="date"
                  label="Vencimento"
                  name='due_date'
                  type="date"
                  variant='outlined'
                  maxWidth
                  onChange={(e) => {
                     onchange(e) 
                    onchangeform(e)
                  }}
                  error={validatesx[0].error}
                  helperText={validatesx[0].error ? validatesx[0].error: ''}
                  value={datamask(data.due_date)}
                 

                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel className={classes.label} id="demo-simple-select-outlined-label">Categoria</InputLabel>
                  <Select
                    className={classes.selectTipoAtendimento}
                    value={data.finance_category_id}
                    name='finance_category_id'
                    onChange={(e) => { onchange(e) 
                      onchangeform(e)}}
                    label="Tipo da categoria"
                    variant="outlined"
                    error={validatesx[1].error}
                    helperText={validatesx[1].error ? validatesx[1].error: ''}
                  >
                    {itens ? itens.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.name} onClick={() => { setIdcategoria(item.id) }}>{item.name} </MenuItem>
                      )

                    }) : ''}
                    

                  </Select>
                </FormControl>


                <TextField
                  InputLabelProps={procedimento ? { shrink: true } : ''}
                  className={classes.input}
                  value={data.description}
                  onChange={(e) => { onchange(e)
                  onchangeform(e) }}
                  type="text"
                  name='description'
                  error={validatesx[2].error}
                  helperText={validatesx[2].error ? 'valor precisa ser preenchido': ''}
                  required
                  id=""
                  label="Descrição"
                  variant="outlined"
                />


                <TextField
                  InputLabelProps={procedimento ? { shrink: true } : ''}
                  className={classes.input}
                  value={'R$ ' + currencyinput(data.value)}
                  onChange={(e) => { onchange(e)
                  onchangeform(e) }}
                  type="text"
                  required
                  error={validatesx[3].error}
                  helperText={validatesx[3].error ? 'valor precisa ser preenchido': ''}
                  name='value'
                  label="Valor"
                  variant="outlined"
                />

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel className={classes.label} id="demo-simple-select-outlined-label">TIPO</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    className={classes.selectTipoAtendimento}
                    value={data.payment_type}
                    name='payment_type'
                    onChange={(e) => { onchange(e) 
                    onchangeform(e)}}
                    error={validatesx[4].error}
                    helperText={validatesx[4].error ? validatesx[4].error: ''}
                    variant="outlined"
                  >
                    <MenuItem key={1} value={'PAGAMENTO'}>PAGAMENTO</MenuItem>
                    <MenuItem key={2} value={'RECEBIMENTO'}>RECEBIMENTO</MenuItem>
                  </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel className={classes.label} id="demo-simple-select-outlined-label">Status</InputLabel>
                  <Select
                    className={classes.selectTipoAtendimento}
                    value={data.status}
                    name='status'
                    onChange={(e) => { onchange(e)
                    onchangeform(e) }}
                    error={validatesx[5].error}
                    helperText={validatesx[5].error ? validatesx[5].error: ''}
                    label="Tipo da categoria"
                    variant="outlined"

                  >
                    <MenuItem key={1} value={'PAGO'}>PAGO</MenuItem>
                    <MenuItem key={2} value={'PENDENTE'}>PENDENTE</MenuItem>
                  </Select>
                </FormControl>


              </div>
              <DialogActions>
                <Button onClick={closeModal} type="button">
                  Cancelar
          </Button>
                {editar ?
                  <Button onClick={() => { editarprocedimento() }} variant="contained" color="primary" className="color-primary">
                    {!load ? 'Salvar alteração' : 'carregando..'}
                  </Button>
                  :
                  <Button onClick={() => { salvaProcedimentos() }} variant="contained" color="primary" className="color-primary">
                    {props.edit.open ? 'Editar' : 'incluir'}
                  </Button>
                }

              </DialogActions>
           
            </DialogContent>
          </form>
        </Dialog>
        <Modal open={openmodalexcluir} close={close} titulo={'Excluir categoria'} desc={'Tem certeza que deseja excluir essa categoria ?'} delete={deletaProcedimento} />

      </ThemeProvider>
    </div>
  );
}