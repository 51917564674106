import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularLoad from '../../../../../Components/Load';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import '../../../css/Relatorios.css';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'total', numeric: false, disablePadding: false, label: 'Total' },
  { id: 'cidade', numeric: true, disablePadding: false, label: 'Cidade' },
];


function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };


  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  cidadeHeigth:{
    height: '330px'
  }
}));

export default function TabelaUsuarios(props) {
  const classes = useStyles();
  const [load, setLoad] = useState(props.load)
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [semResultado, setSemResultado] = React.useState(false);
  const [totalRows, setTotatRows] = useState('1')
  const [cidades, setCidades] = useState(props.cidades);
  const [paginaAtual, setPaginaAtual] = useState(0)


const handleRequestSort = (event, property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrder(isAsc ? 'desc' : 'asc');
  setOrderBy(property);
};

const handleSelectAllClick = (event) => {
  if (event.target.checked) {
    const newSelecteds = rows.map((n) => n.name);
    setSelected(newSelecteds);
    return;
  }
  setSelected([]);
};

const handleClick = (event, name) => {
  const selectedIndex = selected.indexOf(name);
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, name);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1),
    );
  }

  setSelected(newSelected);
};


const isSelected = (name) => selected.indexOf(name) !== -1;

const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

function convertData(value){
  let data = String(value)

  data  = data.split('T')

  data = data[0]

  data = data.split('-')

  data = `${data[2]}/${data[1]}/${data[0]}`

  return(data)

}

 function listaTabelaCidade(){
    if(cidades){
        let arr = []
        cidades.forEach((item)=>{
            var data = {
                cidade: item.city,
                total: item.quantity
            }
            arr.push(data)
        })
        setRows(arr) 
    }
  }

  useEffect(() => {
    setLoad(props.load);
    setCidades(props.cidades)
    listaTabelaCidade()
    if(props.cidades.length<1){
      setSemResultado(true)
    }else{
      setSemResultado(false)
    }
  }, [props]);

const handleChangePage = (event, newPage) => {
    setPaginaAtual(newPage)
  };

  return (
    <div className={classes.root}>
        {!semResultado ?
        !load ? 
        <Paper className={classes.paper}>
                  <TableContainer className={classes.cidadeHeigth}>
                    <Table
                      className={clsx(classes.table,"historico-user-table")} 
                      aria-labelledby="tableTitle"
                      aria-label="enhanced table"
                    >
                    <EnhancedTableHead
                      classes={classes}
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />
                    <TableBody>
                  
                      {stableSort(rows, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.codigo);
                          const labelId = `enhanced-table-checkbox-${index}`;
                        
                          return (
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, row.codigo)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key='1'
                              selected={isItemSelected}
                            >
                              <TableCell align="left" component="th" id={labelId}>
                                {!row.total?'Não possui total': row.total}
                              </TableCell>
                              <TableCell align="left" component="th" id={labelId}>
                                {!row.cidade?'Não possui cidade cadastrada':row.cidade}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                </Table>
              </TableContainer>
                 <TablePagination
                 rowsPerPageOptions={[15]}
                 component="div"
                 count={totalRows}
                 rowsPerPage={rowsPerPage}
                 labelRowsPerPage="Resultados por página"
                 page={paginaAtual}
                 onChangePage={handleChangePage}
               />
               </Paper>
      :  <div className="div-load-lista-modelos"><CircularLoad/></div>
      :''}
    </div>
  );
}