import React, { useState, useEffect } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import ListIcon from '@material-ui/icons/List';
import '../../css/NovoModelo.css';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import './css/style.css'

import { StateGlobal } from '../../../../../ContextAPI/context'
import { keys } from '@material-ui/core/styles/createBreakpoints';
import Corpo from '../../../../../Assets/Images/corpo.png';
import Papagaio from '../../../../../Assets/Images/papagaio.jpg'
import ImgOsteopatia from '../../../../../Assets/Images/ImagensProntuario/osteopatia_pediatrica.png'


const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '97%',
    },
  },
  formControl: {
    marginTop: '1rem',
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',

    background: '#F9F9F9'
  },
  inputEscala: {
    width: '100%',
  },

}));



export default function CardTextoCurto(props) {
  const classes = useStyles();
  const history = useHistory();
  const [newname, setNewname] = useState();
  const [required, setRequirede] = useState(false);
  const [checked, setcheck] = useState(false)
  const [titulocampo, setTituloCampo] = useState();
  const [tipo, setTipo] = useState();
  const [img, setImg] = useState();



  const {
    montagemstate,
    setMontagem,
    setIdcomponentAtual,
    setAtt,
    att,
    idComponentAtual,
    sessaostate
  } = StateGlobal();

  useEffect(() => {

    montagemstate.map((item) => {
      if (item.tempId === props.tempId) {
        setTituloCampo(item.title)
        if(item.required == 0){
          setcheck(false)
        }else{
          setcheck(true)
        }
      }
    })

  }, [idComponentAtual])

  useEffect(() => {
    if (!tipo) {
      setTipo(tipos[0].tipo)
    }

  })

  useEffect(() => {
    tipos.forEach((item, index) => {
      if (tipo == item.tipo) {
       
        setImg(tipos[index].img)
      }
    })

  }, [tipo])

  const tipos = [
    { tipo: 'Sistema Músculo Esquelético', img: Corpo },
    { tipo: 'Sistema Visceral', img: Papagaio },
    { tipo: 'Sistema Craniano', img: Corpo },
    { tipo: 'Sistema Músculo Esquelético', img: Corpo }
  ]

  function handlerChange(e) {
    const value = e.target.value
    setTipo(value)

  }


  return (
    <div className="CardTextoCurto-container" >
      {/* {props.tempId} */}
      <ListIcon className="icon-list-card-input" />
      <div className="container-card-input">

        <div className="titleEscale"   >
          <h3 className="h3-title-card-input" >CRANIOMETRIA</h3>

          <FormControl variant="outlined" className={classes.formControl, classes.inputEscala}>


          </FormControl>
        </div>

        <div className="img-class">
          <img src={ImgOsteopatia} alt="" />
        </div>

        <div className="Card-option">
          <IconButton name={props.tempId} onClick={() => {
            montagemstate.map((item, index) => {
              if (props.tempId === item.tempId) {
                const array = montagemstate
                array.splice(index, 1)

                setMontagem(array)
                setAtt(!att)

              }
            })

         
          }}>
            <DeleteIcon />
          </IconButton>
          <div className="divisor"></div>
          {/* {checked ? 'true ' : 'false'} */}
          <FormControlLabel
            className="label-color"
            name='switch'
            onChange={() => {
              setcheck(!checked)
 


              montagemstate.map((item, index) => {
                if (props.tempId === item.tempId) {

                  item.required = !checked

                }
              })

            }}
            checked={checked}

            control={<Switch color="primary" />}
            label="Obrigatório"
            labelPlacement="start"


          />


        </div>
      </div>
    </div>
  );
}