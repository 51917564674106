import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import {useHistory} from 'react-router-dom';
import conexao from '../../../Services/api';
import ModalExcluir from '../../../Components/Modais/ModalExclusao';
import ModalCriarCupom from './ModalCriarCupom';
import CircularLoad from '../../../Components/Load';
import '../css/Cupons.css';
import { maskedDateFormatter } from '@material-ui/pickers/_helpers/text-field-helper';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}



function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'codigo', numeric: true, disablePadding: false, label: '#' },
  { id: 'cupom', numeric: true, disablePadding: false, label: 'Cupom' },
  { id: 'porcDesconto', numeric: true, disablePadding: false, label: '% de desconto' },
  { id: 'quantidade', numeric: true, disablePadding: false, label: 'Quantidade' },
  { id: 'dataExpiracao', numeric: true, disablePadding: false, label: 'Data de Expiração' },
  // { id: 'acao', numeric: false, disablePadding: true, label: 'Ações' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };



  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    backgroundColor: '#F3F3F3'
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function TableHistorico() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [semCupom, setSemCupom] = React.useState(false);
  const [semResultado, setSemResultado] = React.useState(false);
  const [typeAlert, setTypeAlert] = useState('');
  const [message, setMessage] = useState('');
  const [load, setLoad] = useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [cupomId, setCupomId] = React.useState('');
  const [titulo, setTitulo] = React.useState('');
  const [descricao, setDescricao] = React.useState('');
  const [totalPage, setTotalPage] = useState('')
  const [atualPage, setAtualPage] = useState(0)
  const history = useHistory();
  const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      }
    },
  }, ptBR);
  

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
  
    setAtualPage(newPage)
    listaCupomCriado(newPage)

  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setOpenModalDelete(false);
  }

  useEffect(()=>{
    listaCupomCriado()
  },[])

  
  function deleteCupom(id) {
    setTitulo('Exclusão de Cupom');
    setDescricao('Deseja excluir esse cupom?');
    setOpenModalDelete(true);
    setCupomId(id)
  }

  async function deletaCupom() {
    setLoad(true)
    await conexao.delete(``)
      .then((response) => {
        setLoad(false)
        setOpenModalDelete(false);
        setTypeAlert('success');
        setMessage('Cupom deletado com sucesso');
        setTimeout(() => {
          setTypeAlert('');
          setMessage('');
        }, 1500);
        listaCupomCriado();
      }).catch((error) => {
        setLoad(false)
        setOpenModalDelete(false);
        setTypeAlert('error');
        setMessage('Erro ao deletar Cupom, tente novamente');
        listaCupomCriado();
        setTimeout(() => {
          setTypeAlert('');
          setMessage('');
        }, 1500);
      })
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);



 function listaCupomCriado(pag){
  let pagina
    setLoad(true)
    
    if(pag){
      pagina = pag + 1 
    }else{
      pagina = page + 1 
    }

  conexao.post(`/adm/coupon/list?page=${pagina}`,{
    "search": search,
    "created_at" : "",
    "finished_at" : ""
})
  .then((response) =>{
    console.log(response)
    setMessage('')
    setTypeAlert('')
      setSemResultado(false)
      setSemCupom(false);
      const itens = []
      if(response.data.data.data.length<1){
        setSemResultado(true)
        setMessage('Nenhum resultado encontrado')
        setTypeAlert('error')
      }else{
        setTotalPage(response.data.data.total)
        setRowsPerPage(response.data.data.per_page)
        setAtualPage(response.data.data.current_page-1)

        response.data.data.data.map(function(item){
          itens.push(
            {
              codigo: item.payment_id,
              cupom: item.code,
              porcDesconto: `${item.discount}%`,
              quantidade: item.limit,
              dataExpiracao: item.expired_at
            })
        })
        setSemResultado(false)
        setLoad(false)
      }
  setRows(itens)
    


  }).catch((error)=>{
    setLoad(false)
    setSemResultado(true)
    setMessage('Nenhum resultado encontrado')
    setTypeAlert('error')
  })
  }

  useEffect(() => {
    listaCupomCriado();
  }, []);


  useEffect(() => {
    if(search===''){
      listaCupomCriado();
    }
  
  },[search]);

const handleModal = (value) => {
  if (value) {
    deletaCupom()
  } else {
    handleClose()
  }
};

function dataMask(date){
  let data = date

  data = data.split(' ')

  data = data[0]
  data = data.split('-')

  data = `${data[2]}/${data[1]}/${data[0]}`

  return data

}

const busca = (event) => {
  if (event.key === 'Enter') {
    setLoad(true)
    listaCupomCriado();
  } if (event._reactName === 'onClick') {
    setLoad(true)
    listaCupomCriado();
  }
  return true
}

  return (
    <div className={classes.root}>
         <ThemeProvider theme={theme}>
      {!semCupom ?
      <div className="tableCupons">
      <div className="historico-cupom-envolve-busca">
        <TextField className="buscaHistorico"
          id="outlined-basic" 
          value={search}
          onKeyDown={busca}
          onChange={(ev) => setSearch(ev.target.value)} 
          label="Buscar por código do cupom"  
          color="primary"  
          variant="outlined" 
          InputProps={{
            endAdornment: <InputAdornment position="start"><SearchIcon onClick={(event) => busca(event)} className="iconSearchColor"/></InputAdornment>,
        }}/>
        </div>
        <div className="historico-cupom-div-msg">
            {message !== ''?
              <Alert className="messagensFixed" variant="filled" severity={typeAlert}>
                 {message}
              </Alert>
            :''}
        </div>
        {!semResultado ?
        !load ? 
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={clsx(classes.table,"historico-cupom-table")} 
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.codigo);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.codigo)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.codigo}
                      selected={isItemSelected}
                    >
                      <TableCell align="center" component="th" id={labelId}>
                        {row.codigo}
                      </TableCell>
                      <TableCell align="center">{row.cupom}</TableCell>
                      <TableCell align="center">{row.porcDesconto}</TableCell>
                      <TableCell align="center">{row.quantidade ? row.quantidade : 0}</TableCell>
                      <TableCell align="center">{row.dataExpiracao ? dataMask(row.dataExpiracao) : 'Não cadastrado' }</TableCell>
                      {/* <TableCell align="center">
                        <Button variant="contained" color="primary" className="color-primary btn-edit-licenca" onClick={() => deleteCupom(row.codigo)}>
                          Excluir cupom
                        </Button>
                      </TableCell> */}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15]}
          component="div"
          count={totalPage}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Resultados por página"
          page={atualPage}
          onChangePage={handleChangePage}
        />
      </Paper>
      : <div className="div-load-lista-modelos"><CircularLoad/></div>
      :''}
      </div>
      :
      <div className="historico-cupom-semCupom">
        <h1>Nenhum cupom resgistrado até o momento.</h1>
      </div>
}
</ThemeProvider>
        <ModalExcluir
            handleModal={handleModal}
            open={openModalDelete}
            load={load}
            titulo={titulo}
            descricao={descricao}
        />
    </div>
  );
}