import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tabela from './components/tabelaClinicas';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 0,
    paddingTop: 100,
    backgroundColor:'#F3F3F3',
    minHeight: '100vh',
    height: '100%'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: '100vh',
    backgroundColor: '#F3F3F3'
  },
  espacamento:{
      padding: 20,
      paddingTop: 0
  },
  container:{
    margin: 15,
    marginTop: 0
  }
}));

export default function Clinicas(props) {
  const classes = useStyles();

  const [csvObject, setCsvObject] = useState([]);

  const getTableData = (data) => {

    let dataObjToCSV = data.map((item) => {
      return {
        '#': item.doctor.id,
        'Data de cadastro:': convertData(item.doctor.created_at),
        'Nome:': item.name,
        'E-mail:': item.email,
        'Telefone:': item.doctor.cellphone,
        'Cidade:': item.doctor.city,
        'Estado:': item.doctor.state,
        'Profissão:': item.doctor.speciality.speciality_category.name,
        'Especialidade:': item.doctor.speciality.name,
        'Data de expiração:': convertData(item.doctor.plan?.expired_at)
      }
    })

    setCsvObject(dataObjToCSV)
  }

  function convertData(value){
    let data = String(value)
  
    data  = data.includes('T') ? data.split('T') : data.split(' ')

    data = data[0]

    data = data.split('-')

    data = `${data[2]}/${data[1]}/${data[0]}`

    return(data)
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
      <Grid container className={classes.espacamento}>
        <Grid item xs={12}>
        
        <div className="cabecalho-personalizado">
          <h1>Usuários</h1>
          <div>
            <CSVLink
              data={csvObject}
              filename={'clinicas.csv'}
              separator={";"}
              style={{ textDecoration: 'none' }}
            >  
              <Button
                variant="contained"
                color="primary"
                className="color-primary"
                style={{ textDecoration: 'none'}}
              >
                <GetAppIcon /> 
                Exportar para Excel  
              </Button>
            </CSVLink>  
          </div>
        </div>  
        <div className="tabela-personalizada">
          <Tabela getTableData={getTableData}/>
        </div>
        </Grid>
      </Grid>
      </div>
   
    </div>
  );
}