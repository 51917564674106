import React, {useState, useEffect} from 'react';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { ThemeProvider } from '@material-ui/styles';
import '../css/NovoModelo.css';
import ButtonsFerramentas from './ButtonsFerramentas';
import { StateGlobal } from '../../../../ContextAPI/context'
import CardTextoCurto from '../SecaoInput/CardTextoCurto'

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
          margin: theme.spacing(1),
          width: '93%',
        },
      },
}));

const theme = createMuiTheme({
    palette: {
      secondary: {
        light: '#E7366A',
        main: '#E7366A',
        dark: '#D93364',
        contrastText: '#fff',
      },
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      }
    },
  });

export default function FerramentasLateralDireita() {
  const classes = useStyles();
  const history = useHistory();
  const {    sessaostate,
    setSessaostate,
    montagem,
    setMontagem,
    components,
    setComponents } = StateGlobal();



    function getdata(e){
      
    }

    function testid(e){
     
      return e
    }


  


  // const componentsteste = [
  //   {type:'textocurto' , component: <CardTextoCurto getData={getdata}  testid={testid} />},
  //   {type:'textolongo' ,  component: <CardTextoCurto getData={getdata} testid={testid}/>  },
  // ]

  
  const componentsteste = [
    { component: 'textocurto' },         //ok
    { component: 'textolongo' },         //ok
    { component: 'telefone' },           //ok
    { component: 'data' },               //ok
    { component: 'email' },              //ok
    { component: 'numerico' },           //ok
    { component: 'label'},               //ok
    
    
    { component: 'selecao' },
    { component: 'check' },
    { component: 'escala'},
    { component: 'image'},
    { component: 'tabela'},


//           'odontologico'
//   'osteopatiapediatrica'
// 'desenvolvimentomotor'
// 'curvadecrescimento'
    { component: 'escaladedor'},          //sprint atual
    { component: 'odontologico'},         //sprint atual
    { component: 'osteopatiapediatrica'}, //sprint atual
    { component: 'desenvolvimentomotor'}, //sprint atual
    { component:'curvadecrescimento'}     //sprint atual
  ]

   

  return (
    <div className="NovoModelo-Ferramentas-princ">
        <ThemeProvider theme={theme}>
          {componentsteste.map((item , index)=>{
          const id = Math.floor(Math.random() * 9999);


         

           return <ButtonsFerramentas item={item} index={index} id={id}  />
            
          })}
       
          
        </ThemeProvider>
    </div>
  );
}