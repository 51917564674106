import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../Assets/Images/LogoCP.svg';

const useStyles = makeStyles((theme) => ({
    divBemVindo:{
        marginTop: '5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '87vh',
        width: '100%'
    },
    h1Bv:{
        marginTop: '2rem',
        color: '#8f8f8f',
        fontWeight: '300',
    }
  }));

export default function Home() {
    const classes = useStyles();

  return (
    <div className={classes.divBemVindo}>
        <img src={Logo}/>
        <h1 className={classes.h1Bv}>Bem-vindo(a) ao sistema Administrativo</h1>
    </div>
  );
}