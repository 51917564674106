import {createStore , combineReducers} from 'redux'
import testeReducer from './Teste/teste.reducers'


const rootReducer = combineReducers({


    teste :  testeReducer


})

const store = createStore(rootReducer)


export default store

