import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { StateGlobal } from '../../ContextAPI/context'
import VisibilityIcon from '@material-ui/icons/Visibility';
import CircularLoad from '../../Components/Load';
import conexao from '../../Services/api';
import ModalExcluir from '../../Components/Modais/ModalExclusao';
import PersonIcon from '@material-ui/icons/Person';
import './Listagem.css';
import { cpf, data } from '../../Components/ReduxTeste/Validate'
import Modal from './Modal/ModalExcluir'
import ModalGeral from './Modal/ModalAddAtendente'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    border: 'none',
    marginBottom: '2rem'
  },
  search: {
    background: '#f1f1f1',
    boxShadow: '0px 1px 10px rgba(25, 32, 56, 0.08)',
    borderRadius: '3px',
    height: '45px',
    fontSize: '15px',
    border: 'none',
    padding: '15px',
    minWidth: '350px',

  },
  iconSearch: {
    color: '#8F8F8F'
  }
}));

const theme = createMuiTheme({
  palette: {
    secondary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },
    primary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#8F8F8F',
    }
  },
}, ptBR);


export default function CardModelosProntos() {
  const classes = useStyles();
  const History = useHistory();
  const [atendentes, setAtendentes] = useState([]);
  const [typeAlert, setTypeAlert] = useState();
  const [message, setMessage] = useState();
  const [load, setLoad] = useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [buscacpf, setBuscaCpf] = useState('');
  const [titulo, setTitulo] = React.useState('');
  const [descricao, setDescricao] = React.useState('');
  const [modeloId, setModeloId] = React.useState('');
  const [optionDelete, setOptionDelete] = React.useState('');
  const [dataarr, setDataarr] = useState();
  const [open, setOpen] = useState(false)
  const [idDelete, setIddelete] = useState('')
  const [openModalgeral, setOpenModalgeral] = useState('')
  const [opengeral, setOpengeral] = useState(false)
  const [idEditar, setidEditar] = useState('')
  const [openalert, setOpenalert] = useState({ open: false })

  const [control, setControl] = useState()

  const {

    isEditPatient,
    setIsEditPatient,
    ViewPatient,
    setViewPatient,
    setAttatendente,
    attatendente
  } = StateGlobal();


  // useEffect(() => {
  //   getallAtendents();
  //   setViewPatient(false)

  // },[]);


  useEffect(() => {

    getallAtendents();

  }, [open, attatendente]);


  function close() {
    setOpen(false)
    setOpengeral(false)
  }

  function closeModalGeral() {
    setOpengeral(false)
  }


  ///////////////aqui busca todos os atendentes:
  function getallAtendents() {
    conexao.get('/employee/' + localStorage.getItem('doctorId')).then((res) => {
      setDataarr(res.data.data)
      setAtendentes(res.data.data)
    }).catch((error) => {

    })

  }

  /////////////////barra de busca:
  function busca(e) {

    if (e === '') {
      setDataarr(atendentes)

    } else {
      const arr = dataarr.filter((item) =>
        item.user.name.toLowerCase().includes(e.toLowerCase())
        ||
        item.user.email.toLowerCase().includes(e.toLowerCase())
      )
      setDataarr(arr)
    }

  }

  function Alertshow(msg, type) {
    setOpenalert({ open: true, msg: msg, type: type })
    setTimeout(() => {
      setOpenalert({ open: false })
    }, 2000);
  }

  ///////////////// função delete
  function deleteuser() {
    conexao.delete('/employee/' + idDelete).then((res) => {

      getallAtendents()
      Alertshow('Deletado com sucesso!', 'success')

    }).catch((error) => {
      Alertshow('Erro ao deletalar!', 'success')
    })
  }


  return (
    <div className="">
      <form className={classes.root} noValidate autoComplete="off">
        <FormControl className={clsx(classes.form)}>
          <InputBase
            id="outlined-adornment-weight"
            endAdornment={<InputAdornment position="end">{<IconButton><SearchIcon className={classes.iconSearch}  /></IconButton>}</InputAdornment>}
            labelWidth={0}
            disabled={load ? true : false}
            // onKeyDown={busca}
            onChange={(e) => busca(e.target.value)}
            placeholder="Busque por um atendente"
            className={clsx(classes.search)}
          />
        </FormControl>
      </form>
      <div>
        {load ?
          <div className="div-load-lista-modelos"><CircularLoad /></div>
          : ''}
        {dataarr ?
          dataarr.map((modeloCriado) => (
            <div className="CardModelos-envolve">
              <div className="CardModelos-envolve-text-icon">
                <PersonIcon className="CardModelos-icon-pront" />
                <p>
                  {/* {modeloCriado.user.id} mostrar o ID de cada User_Id */}
                  {modeloCriado.user.name}
                </p>
              </div>
              <div className="CardModelos-envolve-text-icon">
                <div className="CardModelos-icon-pront">E-mail:</div>
                <p>{modeloCriado.user.email}</p>
              </div>
              <div>
                <IconButton onClick={() => {
                  conexao.get('/employee/get/' + modeloCriado.id).then((res) => {                    
                    setAttatendente(res.data.data[0].user)
                   
                    setOpengeral('visible')
                  }).catch((error) => {

                  })


                }}>
                  <VisibilityIcon />
                </IconButton>
                <IconButton onClick={() => {
                  conexao.get('/employee/get/' + modeloCriado.id).then((res) => {  
                    setAttatendente(res.data.data[0].user)  
                    setidEditar(modeloCriado.id)             
                    setOpengeral('edit')
                  }).catch((error) => {

                  })

                }}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => {
                  setOpen(true)
                  setIddelete(modeloCriado.user.id)
                }}>
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          )) :
          <div>
            {message ?
              <div className="Modal-agendamento-alert">
                <Alert variant="filled" severity={typeAlert}>
                  {message}
                </Alert>
              </div>
              : ''}
          </div>
        }
      </div>
      <Modal open={open} titulo={'Excluir Atendente'} desc={'Tem certeza que deseja excluir este atendente ?'} close={close} delete={deleteuser} />
      <ModalGeral className="Color-btn-addModelo" open={opengeral} idedit={idEditar} close={closeModalGeral} />
    </div>
  );
}